import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import axios from 'axios';

import { useConfig } from 'contexts/configContext';
import {
  CountResult,
  HomeCountData,
  TokenCountResult
} from '../components/Home/type';

type SbtCommonContextValue = {
  tokenCountList: TokenCountResult[];
  getTokenCountList: () => void;
  homeCountData: HomeCountData;
};

const SbtCommonContext = createContext<SbtCommonContextValue | null>(null);

export const SbtCommonContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [tokenCountList, setTokenCountList] = useState<TokenCountResult[]>([]);
  const [homeCountData, setHomeCountData] = useState<HomeCountData>(
    {} as HomeCountData
  );

  const { SBT_NODE_SERVICE } = useConfig();

  const getTokenCountList = useCallback(async () => {
    const url = `${SBT_NODE_SERVICE}/npo/count`;
    try {
      const res = await axios.post<CountResult>(url);
      if (res.status === 200 || res.status === 201) {
        const { types, count, holders, todayCount } = res.data;
        setTokenCountList(types);
        setHomeCountData({
          today: todayCount,
          total: count,
          holders
        });
      }
    } catch (error) {
      console.error('get total minted count error: ', error);
    }
  }, [SBT_NODE_SERVICE]);

  useEffect(() => {
    getTokenCountList();
  }, [getTokenCountList]);

  const value = useMemo(
    () => ({
      tokenCountList,
      getTokenCountList,
      homeCountData
    }),
    [tokenCountList, getTokenCountList, homeCountData]
  );

  return (
    <SbtCommonContext.Provider value={value}>
      {children}
    </SbtCommonContext.Provider>
  );
};

export const useSbtCommon = () => {
  const data = useContext(SbtCommonContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSbtCommon can only be used inside of <SbtCommonContext />, please declare it at a higher level.'
    );
  }
  return data;
};
