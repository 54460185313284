import { useMantaWallet } from 'contexts/mantaWalletContext';
import { BabSbt } from 'pages';
import { CalamariBasePage } from 'pages/BasePage';
import { useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

const CalamariPages = () => {
  const { externalAccount } = useMantaWallet();
  const address = externalAccount?.address;
  useEffect(() => {
    if (window?.MetaCRMWidget?.manualConnectWallet) {
      window.MetaCRMWidget.manualConnectWallet(address);
    }

    const handleConnectWidget = () => {
      window.MetaCRMWidget.manualConnectWallet(address);
    };
    document.addEventListener('MetaCRMLoaded', handleConnectWidget);

    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
    };
  }, [address]);

  return (
    <Routes>
      <Route path="calamari">
        <Route index element={<Navigate to="sbt" />} />
        <Route path="sbt/*" element={<BabSbt />} />
      </Route>
    </Routes>
  );
};

const CalamariRoutes = () => {
  return (
    <CalamariBasePage>
      <CalamariPages />
    </CalamariBasePage>
  );
};

const RedirectRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/calamari/sbt" replace />} />
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <RedirectRoutes />
      <CalamariRoutes />
    </Router>
  );
};

export default AppRouter;
