import { u8aToHex } from '@polkadot/util';
import { getWalletClient, signTypedData } from '@wagmi/core';
import BN from 'bn.js';
import { RelayerReturnedCode, WalletErrorCode } from 'constants/ErrorCode';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAccount, useNetwork } from 'wagmi';

import { base58Decode } from '@polkadot/util-crypto';
import { HexString } from '@polkadot/util/types';
import axios from 'axios';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { PostType } from 'contexts/mantaWalletType';
import { useSubstrate } from 'contexts/substrateContext';
import { useTxStatus } from 'contexts/txStatusContext';
import Balance from 'types/Balance';
import TxStatus from 'types/TxStatus';
import { getSignTypeDataParams } from 'utils/signTypedData';
import { UpdateVersionNotification, showInfo } from 'utils/ui/Notifications';
import versionLargerThan from 'utils/versionLarger';
import { zkTokenMap } from '../components/Home/ProjectConstant';
import { MINT_TYPES } from '../components/Home/type';
import { useSbtCommon } from './sbtCommonContext';

type ProofInfo = {
  transaction_data: {
    identifier: any;
    asset_info: any;
    zk_address: { receiving_key: Array<any> };
  };
  proof_id: HexString;
  blur_url: string;
  asset_id: string;
};

export type HasNFTRes = {
  status: boolean;
  count: boolean;
};

export enum VALID_STATUS {
  default,
  networkError,
  noSbtInEvmAddress = 'zero-balance-observed',
  duplicate = 'prior-allow-observed',
  success = 'allow-success',
  fail = 'allow-fail'
}

export enum MINT_STATUS_CODE {
  success = 0,
  notAllow = -1,
  alreadyMinted = -2,
  minting = -7,
  failed = -22,
  duplicated = -23
}

export enum FAUCET_BUTTON_STATUS {
  default,
  disable = 'dripped',
  able = 'non-dripped'
}

export enum FAUCET_STATUS {
  default,
  error,
  success = 'drip-success'
}

type SbtTokenContextValue = {
  tokenValidStatus: VALID_STATUS;
  validateToken: () => void;
  checkAddressMinted: (ethAddress: string) => void;
  isMinted: boolean;
  mintLoading: boolean;
  gasFee: Balance | null;
  getMintGasFee: (options: {
    signature: string;
    signedChainId: string;
  }) => void;
  mintSbtToken: () => void;
  proofInfos: ProofInfo[] | null;
  getEthSignature: () => Promise<void>;
  saveMintData: () => void;
  mintErrMsg: string;
  mintStatusMsg: string;
};

enum paramsKey {
  zkBAB = 'bab',
  zkGalxe = 'galxe',
  zkArbAirdrop = 'arbitrum',
  zkReadON = 'readon',
  zkLinea = '',
  zkCyberConnect = '',
  zkLineaNFT = '',
  zkUltiverse = '',
  zkTaskon = '',
  zkFrontier = '',
  zkFuturist = '',
  zkCertificate = '',
  zkCertificateNFT = '',
  zkOP = '',
  zkGetaverse = '',
  zkKaratDao = '',
  zkBladeDao = ''
}
enum MINT_ID_MAP {
  zkBAB = 1,
  zkGalxe,
  zkArbAirdrop,
  zkReadON,
  zkLinea,
  zkCyberConnect,
  zkLineaNFT,
  zkUltiverse,
  zkTaskon,
  zkFrontier,
  zkFuturist,
  zkCertificate,
  zkCertificateNFT,
  zkGetaverse = 34,
  zkOP = 35,
  zkKaratDao = 51,
  zkBurgerCities = 52,
  zkBladeDao = 63
}

const SbtTokenContext = createContext<SbtTokenContextValue | null>(null);

export const SbtTokenContextProvider = ({
  children,
  tokenType
}: {
  children: ReactNode;
  tokenType: MINT_TYPES;
}) => {
  const [tokenValidStatus, setTokenValidStatus] = useState<VALID_STATUS>(
    VALID_STATUS.default
  );
  const [isMinted, toggleIsMinted] = useState<boolean>(true);
  const [mintStatusMsg, setMintStatusMsg] = useState<string>('');
  const mintLoadingRef = useRef<boolean>(true);
  const mintLoading = mintLoadingRef.current;
  const [assetId, setAssetId] = useState<string>('');
  const [gasFee, setGasFee] = useState<Balance | null>(null);
  const [proofInfos, setProofInfos] = useState<ProofInfo[] | null>(null);
  const [post, setPost] = useState<PostType | null>(null);
  const [sbtTokenId, setSbtTokenId] = useState<string>('');
  const [signedChainId, setSignedChainId] = useState('');
  const [signature, setSignature] = useState<any | null>(null);
  const [mintErrMsg, setMintErrMsg] = useState('');
  const ethAddressRef = useRef<string | undefined>('');
  const [validateShortlistOnce,setValidateShortlist] = useState({});

  const { api } = useSubstrate();
  const { privateWallet } = useMantaWallet();
  const {
    NETWORK_NAME,
    IS_TESTNET,
    GIANT_SQUID_SERVICE,
    FAUCET_SERVICE,
    SBT_NODE_SERVICE
  } = useConfig();
  const { externalAccount } = useMantaWallet();
  const { setTxStatus } = useTxStatus();
  const { address: ethAddress } = useAccount();
  const { chain } = useNetwork();
  const { getTokenCountList } = useSbtCommon();
  const { MIN_REQUIRED_WALLET_VERSION } = useConfig();
  const { mantaWalletVersion } = useMantaWallet();
  const outdated = versionLargerThan(
    MIN_REQUIRED_WALLET_VERSION,
    mantaWalletVersion
  );

  const checkAddressMinted = useCallback(
    async (ethAddress: string) => {
      if (!api || !ethAddress) {
        console.log('mintLoading: api or ethAddress is null', api, ethAddress);
        return false;
      }
      try {
        const maybeAssetId: any =
          await api?.query?.mantaSbt?.evmAccountAllowlist(
            MINT_ID_MAP[tokenType],
            ethAddress?.toLowerCase()
          );
        mintLoadingRef.current = false;
        if (maybeAssetId?.isNone) {
          setMintStatusMsg('Mint');
          toggleIsMinted(false);
          console.log('AssetID is None');
          return false;
        }
        const assetIdInfo = maybeAssetId?.unwrap();

        if (assetIdInfo.isAlreadyMinted) {
          setMintStatusMsg('Minted');
          toggleIsMinted(true);
          setAssetId('');
          return true;
        } else {
          toggleIsMinted(false);
          setAssetId(assetIdInfo.asAvailable.toString());
          return false;
        }
      } catch (error) {
        showInfo('checkAddressMinted error: ' + error);
      }
    },
    [api, tokenType]
  );

  const resetData = useCallback(() => {
    setPost(null);
    setProofInfos(null);
    mintLoadingRef.current = false;
    setGasFee(null);
    setSignature('');
    setAssetId('');
  }, []);

  const getTransactionData = useCallback(async () => {
    mintLoadingRef.current = true;
    if (!externalAccount?.address || !assetId || !privateWallet) {
      mintLoadingRef.current = false;
      return;
    }
    try {
      const ret = await privateWallet?.multiSbtPostBuild({
        sbtInfoList: [
          {
            assetId
          }
        ],
        network: NETWORK_NAME
      });

      mintLoadingRef.current = false;
      setMintErrMsg('');

      if (ret) {
        const { posts, transactionDatas } = ret;
        const bytes = base58Decode(
          (externalAccount?.meta?.zkAddress as string) ?? ''
        );
        const addressBytes = Array.from(bytes);
        if (!transactionDatas?.length) {
          showInfo(`No TransactionData: assetId ${assetId}`);
        }
        const proofInfos = transactionDatas?.map((tx: any) => {
          const proofId = u8aToHex(
            tx[0].ToPrivate[0]['utxo_commitment_randomness']
          );
          const identifier = tx[0].ToPrivate[0];
          const asset_info = tx[0].ToPrivate[1];
          return {
            transaction_data: {
              asset_info: {
                id: asset_info.id,
                value: Number(asset_info.value)
              },
              identifier,
              zk_address: {
                receiving_key: addressBytes
              }
            },
            proof_id: proofId,
            blur_url: zkTokenMap[tokenType].imgUrl,
            asset_id: assetId,
            token_type: tokenType,
            eth_address: ethAddress
          };
        });
        setProofInfos(proofInfos);
        setPost(posts[0][0]);
      }
    } catch (e: any) {
      if (e.code === WalletErrorCode.locked) {
        setMintErrMsg(
          e.message ?? 'Please unlock your wallet and refresh the page'
        );
      }
      mintLoadingRef.current = false;
      if (outdated) {
        UpdateVersionNotification();
        return;
      }
      showInfo(`getTransactionData Error: ${e.code} ${e.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    externalAccount?.address,
    externalAccount?.meta?.zkAddress,
    assetId,
    privateWallet,
    NETWORK_NAME,
    // tokenType, // fix the asset id invalid bug
    ethAddress
  ]);

  useEffect(() => {
    getTransactionData();
  }, [getTransactionData]);

  const submitBnbAddress = useCallback(() => {
    // since zkLinea we don't need giant squid
    const addressType = paramsKey[tokenType];
    if (ethAddress && addressType) {
      const url = `${GIANT_SQUID_SERVICE}/squid/submitAddrToCampaign`;
      const data = {
        addressType,
        address: ethAddress?.toLowerCase()
      };
      axios.post(url, data);
    }
  }, [GIANT_SQUID_SERVICE, ethAddress, tokenType]);

  const validateToken = useCallback(async () => {
    ethAddressRef.current = ethAddress;
    const validateShortlistOnceKey = `${ethAddressRef.current}-${tokenType}`;
    const validateShortlistOnceValue = validateShortlistOnce[validateShortlistOnceKey];
    if (validateShortlistOnceValue || !ethAddressRef.current) {
      return;
    }
    resetData();
    const url = `${SBT_NODE_SERVICE}/npo/attest/shortlist`;
    const data = {
      shortlist: ethAddressRef.current?.toLowerCase(),
      token_type: tokenType
    };
    try {
      setTokenValidStatus(VALID_STATUS.default);
      const ret = await axios.post<{ status: VALID_STATUS; token: string }>(
        url,
        data
      );
      if (ret.status === 200 || ret.status === 201) {
        const { status, token } = ret?.data ?? {};
        // reset data when current ethAddress is empty
        if (!ethAddressRef.current) {
          setTokenValidStatus(VALID_STATUS.default);
          setSbtTokenId('');
          return;
        }
        // VALID_STATUS.duplicate means already successed last time
        if (
          status === VALID_STATUS.success ||
          status === VALID_STATUS.duplicate
        ) {
          setSbtTokenId(token);
          setTokenValidStatus(VALID_STATUS.success);
        } else {
          setTokenValidStatus(status);
          setSbtTokenId(token);
          if (status !== VALID_STATUS.noSbtInEvmAddress)
            showInfo('validateToken: ' + status); // log
        }
        setValidateShortlist({[validateShortlistOnceKey]:true});
        checkAddressMinted(ethAddressRef.current);
      }
    } catch (e) {
      setTokenValidStatus(VALID_STATUS.networkError);
      console.log('valid evm address error: ', e);
    }
  }, [FAUCET_SERVICE, checkAddressMinted, ethAddress, resetData, tokenType, validateShortlistOnce]);

  const getMintTx = useCallback(
    ({
      signature,
      signedChainId
    }: {
      signature: string;
      signedChainId: string;
    }) => {
      if (!ethAddress || !signature || !post || !signedChainId) {
        return;
      }

      return api?.tx.mantaSbt.mintSbtEth(
        post,
        signedChainId,
        signature,
        MINT_ID_MAP[tokenType],
        null,
        null,
        sbtTokenId
      );
    },
    [ethAddress, post, tokenType, api?.tx.mantaSbt, sbtTokenId]
  );

  const getMintGasFee = useCallback(
    async ({
      signature,
      signedChainId
    }: {
      signature: string;
      signedChainId: string;
    }) => {
      const tx = getMintTx({
        signature,
        signedChainId
      });
      if (tx) {
        const paymentInfo = await tx?.paymentInfo(
          externalAccount?.address ?? ''
        );
        setGasFee(
          Balance.Native(
            { IS_TESTNET, NETWORK_NAME },
            new BN(paymentInfo?.partialFee.toString() ?? '')
          )
        );
      }
    },
    [IS_TESTNET, NETWORK_NAME, externalAccount?.address, getMintTx]
  );

  const mintSbtToken = useCallback(async () => {
    const isMinted = await checkAddressMinted(ethAddress?.toLowerCase() ?? '');
    if (isMinted) {
      setTxStatus(TxStatus.failed('Already Minted'));
      return;
    }

    if (!ethAddress || !signature || !post || !signedChainId) {
      setTxStatus(
        TxStatus.failed('Network Error, please refresh the page and try again')
      );
      return;
    }
    const url = `${SBT_NODE_SERVICE}/npo/saveSbtPost`;

    const mintId = MINT_ID_MAP[tokenType];
    const proofsReqData = {
      proof_info: proofInfos,
      address: externalAccount?.address,
      model_id: '',
      token_type: tokenType
    };
    const data = {
      post: JSON.stringify(post),
      chainId: signedChainId,
      signature,
      mintId,
      sbtId: sbtTokenId,
      proofsReqData
    };
    try {
      const ret = await axios.post<{ code: number; message: string }>(
        url,
        data
      );
      if (ret?.status === 200 || ret?.status === 201) {
        if (ret?.data?.code === RelayerReturnedCode.OK) {
          setTxStatus(
            TxStatus.finalized(
              '',
              '',
              'Minting in progress. Please wait a moment and check in the “My zkNFTs” page later. ',
              true
            )
          );
        } else {
          setTxStatus(TxStatus.failed(ret?.data?.message ?? 'Network Busy'));
        }
      }
    } catch (e: any) {
      console.error('fetch relayer to mint error: ', e);
      const retData = e?.response?.data;
      if (retData) {
        const message = retData?.message ?? '';
        setTxStatus(TxStatus.failed(message));
      } else {
        setTxStatus(TxStatus.failed(e?.message ?? 'Network Error'));
      }
    } finally {
      checkAddressMinted(ethAddress?.toLowerCase() ?? ''); // re-check mint status
    }
  }, [
    SBT_NODE_SERVICE,
    checkAddressMinted,
    ethAddress,
    externalAccount?.address,
    post,
    proofInfos,
    sbtTokenId,
    setTxStatus,
    signature,
    signedChainId,
    tokenType
  ]);

  const getEthSignature = useCallback(async () => {
    if (post?.proof && api) {
      try {
        mintLoadingRef.current = true;
        const salt = (await api?.rpc?.chain?.getBlockHash(0)).toHex();
        const chainId = chain?.id ?? '';
        const params = getSignTypeDataParams({
          proof: post?.proof,
          salt,
          chainId: Number(chainId)
        });
        const walletClient = await getWalletClient();
        if (!walletClient) {
          return;
        }
        const signature =
          (await signTypedData({
            ...params,
            primaryType: 'Transaction'
          })) ?? '';
        setSignedChainId(String(chainId));
        setSignature(signature);
        await getMintGasFee({
          signature,
          signedChainId: String(chainId)
        });
      } catch (e: any) {
        showInfo('getEthSignature blocked', e);
      } finally {
        mintLoadingRef.current = false;
      }
    } else {
      showInfo('No proof or api: ' + post?.proof + ' ' + api);
    }
  }, [api, chain?.id, getMintGasFee, post?.proof]);

  const saveMintData = useCallback(() => {
    checkAddressMinted(ethAddress?.toLowerCase() ?? '');
    getTokenCountList();
    submitBnbAddress();
  }, [ethAddress, checkAddressMinted, getTokenCountList, submitBnbAddress]);

  const value = useMemo(
    () => ({
      tokenValidStatus,
      validateToken,
      checkAddressMinted,
      isMinted,
      gasFee,
      getMintGasFee,
      mintSbtToken,
      saveMintData,
      proofInfos,
      getEthSignature,
      mintLoading,
      mintErrMsg,
      mintStatusMsg
    }),
    [
      tokenValidStatus,
      validateToken,
      checkAddressMinted,
      isMinted,
      gasFee,
      getMintGasFee,
      mintSbtToken,
      saveMintData,
      proofInfos,
      getEthSignature,
      mintLoading,
      mintErrMsg,
      mintStatusMsg
    ]
  );
  return (
    <SbtTokenContext.Provider value={value}>
      {children}
    </SbtTokenContext.Provider>
  );
};

export const useSbtToken = () => {
  const data = useContext(SbtTokenContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSbtToken can only be used inside of <SbtTokenContext />, please declare it at a higher level.'
    );
  }
  return data;
};
