
const ConfirmModalContent = ({
  handleCancel,
  handleDelete
}: {
  handleCancel: () => void;
  handleDelete: () => void;
}) => {
  return (
    <>
      <span className="absolute z-10 top-5 left-6 text-white text-xl">
        Please Confirm
      </span>
      <div className="flex flex-col p-6 w-96">
        <div className="mt-8 flex text-white">
          <div className="mt-6 flex flex-col items-center text-sm text-left text-white text-opacity-60">
            <div>
              Deleted Proof key will be invalid and can no longer been used. All
              accounts associated with this proof key will be unbind
              automatically.
            </div>
            <div className="mt-8 w-full">
              There is no other way to recover deleted proof key.
            </div>
          </div>
        </div>
        <button
          onClick={() => handleDelete()}
          className="mr-4 mt-6 h-10 w-full bg-error bg-opacity-20 border border-error text-error font-red-hat-text text-sm cursor-pointer rounded-lg">
          Delete
        </button>
        <button
          onClick={() => handleCancel()}
          className="mr-4 mt-4 h-10 w-full text-white border border-white font-red-hat-text text-sm cursor-pointer rounded-lg">
          Cancel
        </button>
      </div>
    </>
  );
};

export default ConfirmModalContent;
