import NETWORK from 'constants/NetworkConstants';
import classNames from 'classnames';
import Icon, { IconName } from 'components/Icon';
import { Node } from 'config';
import { useConfig } from 'contexts/configContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import linkArrow from 'resources/icons/link_arrow.svg';
import { ONLINE_BASE_URL } from './NavLinks';

const ChainDropdownItem = ({
  prevSubPath,
  node,
  activeNode
}: {
  node: Node;
  activeNode: Node;
  prevSubPath: string;
}) => {
  const selectedNetwork = activeNode.name === node.name;
  const curSubPath = node.path;
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (pre: string, cur: string) => {
    const openNewTab = cur === `/${NETWORK.DOLPHIN.toLowerCase()}`;
    // to mantapay url
    if (openNewTab) {
      return window.open(`${ONLINE_BASE_URL}/dolphin`, '_blank', 'noreferrer');
    }
    navigate(`${location.pathname.replace(pre, cur)}`);
  };

  return (
    <div
      onClick={() => handleNavClick(prevSubPath, curSubPath)}
      className="cursor-pointer border border-white-light bg-white bg-opacity-5 rounded-lg py-3 pl-3.5"
      key={node.name}>
      <div className="flex items-center gap-5 w-full">
        <Icon
          name={(node.logo || (node.name as string).toLowerCase()) as IconName}
          className="w-6 h-6"
        />
        <div className="unselectable-text text-white w-32">
          {node.name}&nbsp;
          {node.testnet ? 'Testnet' : 'Network'}
        </div>
        <div className="ml-1">
          {selectedNetwork ? (
            <Icon name={'greenCheck'} className="w-4 h-4" />
          ) : (
            <Icon name={'unfilledCircle'} className="w-4 h-4" />
          )}
        </div>
      </div>
    </div>
  );
};

const ChainSelector = () => {
  const config = useConfig();
  const { txStatus } = useTxStatus();
  const prevSubPath = `/${config.NETWORK_NAME.toLowerCase()}`;

  const nodes = config.NODES;
  const activeNode =
    nodes.find(
      (node) => node.name.toString() === config.NETWORK_NAME.toString()
    ) || nodes[0];
  const [showNetworkList, setShowNetworkList] = useState(false);

  const enum IconName {
    mantaAtlantic = 'mantaAtlantic',
    mantaPacific = 'mantaPacific',
  }

  const disabled = txStatus?.isProcessing();

  const handleLogoClick = () => {
    window.open('https://manta.network/', '_blank', 'noreferrer');
  };

  return (
    <div
      className="relative font-red-hat-mono text-sm"
      onClick={()=>setShowNetworkList(!showNetworkList)}
    >
      <div
        className={classNames(
          'logo-content flex items-center lg:flex relative cursor-pointer w-66',
          { disabled: disabled }
        )}>
        <Icon
          name={IconName.mantaAtlantic}
        />
        <img src={linkArrow} className={classNames('ml-8 transform',showNetworkList?'rotate-270':'rotate-90')} />
      </div>
      {showNetworkList && (
        <div
          className={classNames(
            'cursor-pointer unselectable-text flex flex-col w-48 gap-2 bg-nav rounded-b-lg p-2',
            'absolute -left-4 top-14 z-50  font-light text-secondary'
          )}>
          <div
            className="opacity-60 p-2 hover:bg-green-light-half hover:opacity-100 rounded-lg">
            <Icon name="mantaAtlantic" />
          </div>
          <hr className="border-1 border-white border-opacity-10"/>
          <div
            onClick={()=>window.open('https://npo-evm.manta.network/', '_blank', 'noreferrer')}
            className="opacity-60 p-2 hover:bg-green-light-half hover:opacity-100 rounded-lg">
            <Icon name="mantaPacific" />
          </div>

        </div>
      )}
    </div>
  );
};

export default ChainSelector;
