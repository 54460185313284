import Icon, { IconName } from 'components/Icon';
import { ReactNode } from 'react';

import CampaignLink from '../CampaignLink';
import { MINT_TYPES, ZK_CATERGORY } from './type';

export const onGoingProjectTypes = [
  MINT_TYPES.zkBladeDao,
  MINT_TYPES.zkBurgerCities,
  MINT_TYPES.zkKaratDao,
  MINT_TYPES.zkGetaverse,
  MINT_TYPES.zkOP,
  MINT_TYPES.zkCertificateNFT,
  MINT_TYPES.zkFuturist,
  MINT_TYPES.zkFrontier,
  MINT_TYPES.zkLineaNFT,
  MINT_TYPES.zkTaskon,
  MINT_TYPES.zkUltiverse,
  MINT_TYPES.zkLinea,
  MINT_TYPES.zkCyberConnect,
  MINT_TYPES.zkReadON,
  MINT_TYPES.zkArbAirdrop,
  MINT_TYPES.zkGalxe,
  MINT_TYPES.zkBAB
];
const zkBABContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    The zkBAB provides Binance users who have completed Identity Verification
    with a digital verification experience akin to the BAB Token, with the added
    benefit of an additional layer of privacy. This enhanced privacy feature
    enables users to verify their Binance KYC without exposing any sensitive
    information such as their wallet address, assets held within the wallet, or
    transactions conducted using the wallet address. By using zkBABs, users can
    fulfill the Binance identity verification requirements in Web3 while keeping
    their information completely confidential. To mint your zkBAB, kindly refer
    to the link below. For further details on BAB Token, please refer to the
    information page provided on{' '}
    <a
      href="https://www.binance.com/en/BABT?source=header"
      className="text-check"
      target="_blank"
      rel="noopener noreferrer">
      Binance.
    </a>
  </div>
);
const zkGalxeContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Galxe zkPassport is a cutting-edge digital identity solution that combines
    the security and anonymity of zero-knowledge (zk) technology with the
    convenience and universality of Galxe Passport. By leveraging zk technology,
    you can authenticate themselves and prove ownership of Galxe Passport
    without revealing any additional information about themselves or your
    assets. This makes zkPassport a more secure and private way of managing
    digital identities in both Web3&Web2 spaces, while also providing a seamless
    user experience.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/passport',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Galxe Passport</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkAirContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    The ARB Early Supporter zkPass lets Arbitrum community members who have
    received the Arbitrum Airdrop to prove, in a privacy-preserving manner, that
    they have received an airdrop and have been a long-term supporter of
    Arbitrum. By using this zkPass, users do not have to reveal any identifiable
    information such as wallet addresses (or the contents of their wallet) or
    personal information. The ARB Early Supporter zkPass offers a powerful and
    unique solution to verify users&apos; participantation for the Airdrop while
    ensures security and privacy at the same time. Start building your private
    on-chain identity on Arbitrum today with the ARB Early Supporter zkPass.
  </div>
);

const zkReadONContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    The ReadON zkPass offers a privacy-enhanced version of the ReadON Pass,
    powered by ZK. By proving their ownership and eligibility using this ReadON
    zkPass, users can also participate in the ReadON ecosystem without revealing
    their personal information or wallet address. The ReadON zkPass is designed
    to incentivize user engagement and participation on the platform while
    providing a more private and secure way to access content. By leveraging zk
    technology, ReadON zkPass can offer users a powerful and innovative solution
    for participating in the ReadON ecosystem in a more secure and private
    manner.
  </div>
);

const zkLineaBridgeContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Click&nbsp;
    <a
      href="https://www.asmatch.app/"
      className="text-check"
      target="_blank"
      rel="noopener noreferrer">
      here
    </a>
    &nbsp;to download AsMatch and bind this zkSBT to match more Linea zkPioneer
    users. Manta is partnering with Linea to launch Linea zkPioneer-Bridge week
    for Linea community members in recognition of briding assets from other
    chains to Linea. Linea zkPioneer-Bridge week allows Linea community members
    to have a zkDID when enjoying Web3 products and experiences. By minting a
    Linea zkPioneer, you will be able to use it in Manta ecosystem projects with
    privacy, and, furthermore, with your long-time-built valuable identity
    without disclosing your exact address. With Manta's zk innovation-proof key,
    Manta's zkSBT will be able to integrate with any mobile application in Web2
    & Web3 and also integrate with any chain and ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/Linea/campaign/GCd1YUZyrN',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Linea Bridge week OAT</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkLineaNFTContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Click&nbsp;
    <a
      href="https://www.asmatch.app/"
      className="text-check"
      target="_blank"
      rel="noopener noreferrer">
      here
    </a>
    &nbsp;to download AsMatch and bind this zkSBT to match more Linea zkPioneer
    users. Manta is partnering with Linea to launch Linea zkPioneer-NFT week for
    Linea community members in recognition of being a part of Linea NFT
    community .Linea zkPioneer-NFT week allows Linea community members to have a
    zkDID when enjoying Web3 products and experiences. By minting a Linea
    zkPioneer, you will be able to use it in Manta ecosystem projects with
    privacy, and, furthermore, with your long-time-built valuable identity
    without disclosing your exact address. With Manta's zk innovation-proof key,
    Manta's zkSBT will be able to integrate with any mobile application in Web2
    & Web3 and also integrate with any chain and ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/Linea/campaign/GCEMnUEySZ',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Linea NFT week OAT</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkCyberConnectContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Cyberconnect to launch Cyberconnect zkProfile for
    Cyberconnect users: Hold Cyberconnect profile & 10+ W3STs. Cyberconnect
    zkProfile allows Cyberconnect users to have a zkDID when enjoying Web3
    products and experiences. By minting a Cyberconnect zkProfile, you will be
    able to use it in Manta ecosystem projects with privacy, and, furthermore,
    with your long-time-built valuable identity without disclosing your exact
    address. With Manta's zk innovation-proof key, Manta's zkSBT will be able to
    integrate with any mobile application in Web2 & Web3 and also integrate with
    any chain and ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://cyberconnect.notion.site/Link3-Guide-to-Personal-Profiles-9c22ca8580da405f894d87ea8769b0c1',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Personal Profile</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkUltiverseContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Ulitverse to launch Ultiverse zkGuardian for
    Ultiverse community members: Meta Merge Pets & Ultiverse-Moonlight & OR
    ElectricSheep NFT holders. Ultiverse zkGuardian allows Ultiverse community
    members to have a zkDID when enjoying Web3 products and experiences. By
    minting a Ultiverse zkGuardian, you will be able to use it in Manta
    ecosystem projects with privacy, and, furthermore, with your long-time-built
    valuable identity without disclosing your exact address. With Manta's zk
    innovation-proof key, Manta's zkSBT will be able to integrate with any
    mobile application in Web2 & Web3 and also integrate with any chain and
    ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://mission.ultiverse.io/',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Guardian NFT Collection</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkTaskonContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Taskon to launch Taskon zkElite for Taskon
    community members in recognition of participating CAP campaigns on Taskon.
    Taskon zkElite allows Taskon community members to have a zkDID when enjoying
    Web3 products and experiences. By minting a Taskon zkElite, you will be able
    to use it in Manta ecosystem projects with privacy, and, furthermore, with
    your long-time-built valuable identity without disclosing your exact
    address. With Manta's zk innovation-proof key, Manta's zkSBT will be able to
    integrate with any mobile application in Web2 & Web3 and also integrate with
    any chain and ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open('https://taskon.xyz', '_blank', 'noopener,noreferrer');
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Taskon CAP</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkFrontierContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Cradles to launch Cradles zkFrontier for Cradles
    community members and Manta community members. Cradles zkFrontier allows
    Cradles community members to have a zkDID when enjoying Web3 products and
    experiences. By minting a Cradles zkFrontier, you will be able to use it in
    Manta ecosystem projects with privacy, and, furthermore, with your
    long-time-built valuable identity without disclosing your exact address.
    With Manta's zk innovation-proof key, Manta's zkSBT will be able to
    integrate with any mobile application in Web2 & Web3 and also integrate with
    any chain and ecosystem. Click here to check if you are eligible for this
    minting event with a Cradles campaign OAT or Manta Gaint squid NFT.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/cradles/campaign/GCu1HUVQEk',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Cradles campaign OAT</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/calamarinetwork/campaign/GCTmWUg4C4',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="ml-6 mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Manta Gaint squid NFT</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkFuturistContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with QuestN to launchQuestN zkFuturist for QuestN
    community members. QuestN zkFuturist allows QuestN community members to have
    a zkDID when enjoying Web3 products and experiences. By minting a QuestN
    zkFuturist, you will be able to use it in Manta ecosystem projects with
    privacy, and, furthermore, with your long-time-built valuable identity
    without disclosing your exact address. With Manta's zk innovation-proof key,
    Manta's zkSBT will be able to integrate with any mobile application in Web2
    & Web3 and also integrate with any chain and ecosystem.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://app.questn.com/questercamp/achievement?uid=46',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>QuestN SBT</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkCertificateContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with POMP to launch POMP zkCertificate for POMP
    community members. POMP zkCertificate allows POMP community members to have
    a zkDID when enjoying Web3 products and experiences. By minting a POMP
    zkCertificate, you will be able to use it in Manta ecosystem projects with
    privacy, and, furthermore, with your long-time-built valuable identity
    without disclosing your exact address. With Manta's zk innovation-proof key,
    Manta's zkSBT will be able to integrate with any mobile application in Web2
    & Web3 and also integrate with any chain and ecosystem.
  </div>
);
const zkCertificateNFTContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with POMP to launch POMP NFT zkCertificate for POMP
    community members. POMP NFT zkCertificate allows POMP community members to
    have a zkDID when enjoying Web3 products and experiences. By minting a POMP
    NFT zkCertificate, you will be able to use it in Manta ecosystem projects
    with privacy, and, furthermore, with your long-time-built valuable identity
    without disclosing your exact address. With Manta's zk innovation-proof key,
    Manta's zkSBT will be able to integrate with any mobile application in Web2
    & Web3 and also integrate with any chain and ecosystem.
  </div>
);

const zkOPContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is launching Optimism early supporter zkPass for Optimism community
    members. Optimism early supporter zkPass allows Optimism community members
    to have a zkDID when enjoying Web3 products and experiences. By minting an
    Optimism early supporter zkPass, you will be able to use it in Manta
    ecosystem projects with privacy, and, furthermore, with your long-time-built
    valuable identity without disclosing your exact address. With Manta's proof
    key, Manta's zkSBT can integrate with any mobile application in Web2 & Web3
    and also integrate with any chain and ecosystem. Who is eligible: You will
    be eligible to this minting event if you received airdrop from Optimism.
  </div>
);
const zkGetaverseContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Getaverse to launch Getaverse zkInitiator for
    Getaverse community members. Getaverse zkInitiator allows Getaverse
    community members to have a zkDID when enjoying Web3 products and
    experiences. By minting a Getaverse zkInitiator, you will be able to use it
    in Manta ecosystem projects with privacy, and, furthermore, with your
    long-time-built valuable identity without disclosing your exact address.
    With Manta's zk innovation-proof key, Manta's zkSBT will be able to
    integrate with any mobile application in Web2 & Web3 and also integrate with
    any chain and ecosystem. Who is eligible: you will be eligible if you have
    one of these four Getaverse SBT/NFT.
    <div className="flex">
      <CampaignLink
        start={true}
        name="Getaverse SBT on Arbitrum"
        link="https://arbiscan.io/token/0xd104c123daf610446541f6e9b1c086ec06510843#balances"
      />
      <CampaignLink
        name="On Optimism"
        link="https://optimistic.etherscan.io/token/0xA077FA66Cf4C8044Ca4680db80B07CE85E8Af0B4"
      />
      <CampaignLink
        name="On zkSync"
        link="https://explorer.zksync.io/address/0x087F849084B8bc8ea9A558B65D61B9F0E9E90bdD"
      />
      <CampaignLink
        name="On BSC"
        link="https://www.bscscan.com/token/0x04aa96852dd7149cd62bbfbb521d989166c342ac"
      />
      <CampaignLink
        name="Getaverse NFT"
        link="https://www.bscscan.com/token/0xbca5c86a1f09ff62ef52b2999eadc74a7b177d8d"
      />
    </div>
  </div>
);

const zkKaratDaoContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with KaratDAO to launch KaratDAO zkBunsr for KaratDAO
    community members. KaratDAO zkBuns allows KaratDAO community members to have
    a zkDID when enjoying Web3 products and experiences. By minting a KaratDAO
    zkBuns, you will be able to use it in Manta ecosystem projects with privacy,
    and, furthermore, with your long-time-built valuable identity without
    disclosing your exact address. With Manta's zk innovation-proof key, Manta's
    zkSBT will be able to integrate with any mobile application in Web2 & Web3
    and also integrate with any chain and ecosystem.Who is eligible: You will be
    eligible to this minting event if you hold one of these NFTs:
    <div className="flex">
      <CampaignLink
        start={true}
        name="Claimer NFT"
        link="https://www.okx.com/web3/marketplace/nft/collection/zksync-era/karat-network-claimer
        "
      />
      <CampaignLink
        name="Validator NFT"
        link="https://www.okx.com/web3/marketplace/nft/collection/zksync-era/karat-network-validator
        "
      />
      <CampaignLink
        name="Verifiable Credential NFT"
        link="https://www.okx.com/web3/marketplace/nft/collection/zksync-era/karat-vc-registry"
      />
    </div>
  </div>
);

const zkBurgerCitiesContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with BurgerCities to launch zkburger for BurgerCities
    community members. zkBurger allows BurgerCities community members to have a
    zkDID when enjoying Web3 products and experiences. By minting a zkBurger,
    you will be able to use it in Manta ecosystem projects with privacy, and,
    furthermore, with your long-time-built valuable identity without disclosing
    your exact address. With Manta's zk innovation-proof key, Manta's zkSBT will
    be able to integrate with any mobile application in Web2 & Web3 and also
    integrate with any chain and ecosystem. Who is eligible: You will be
    eligible to this minting event if you hold one of these NFTs:
    <div className="flex">
      <CampaignLink
        start={true}
        name="Burger Token"
        link="https://bscscan.com/token/0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f"
      />
      <CampaignLink
        name="NFTskin721"
        link="https://bscscan.com/token/0xa1f7a5208f78b02ea4eccec3da663b5cd9a9e912"
      />
      <CampaignLink
        name="Hero721"
        link="https://bscscan.com/token/0x57cb9c450c64efb071a44173d815edeba9344632"
      />
    </div>
  </div>
);

const zkBladeDaoContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
   Manta is partnering with BladeDAO to launch BladeDAO zkBlade for BladeDAO community members. BladeDAO zkBlade allows BladeDAO members to have a zkDID when enjoying Web3 products and experiences. By minting a BladeDAO zkBlade, you will be able to use it in Manta ecosystem projects with privacy, and, furthermore, with your long-time-built valuable identity without disclosing your exact address. With Manta's zk innovation-proof key, Manta's zkSBT will be able to integrate with any mobile application in Web2 & Web3 and also integrate with any chain and ecosystem.
    <div className="mt-4">
   Who is eligible: You will be eligible to this minting event if you ever minted any collection of zkSBT from Manta's ecosystem project: NPO
    </div>
    <div className="mt-4">
   Click here to see if you are eligible:
    </div>
    <div className="flex">
      <CampaignLink
        start={true}
        name="zkSBT"
        link="https://npo.manta.network/calamari/sbt"
      />
    </div>
  </div>
);

type ZkToken = {
  title: string;
  category: ZK_CATERGORY;
  imgUrl: string;
  imgUrlHorizontal: string;
  content: ReactNode;
  contentHorizontal: ReactNode;
  noSbtInEvmAddressMsg: string;
  detectedMsg: string;
  logo1?: IconName;
  logo2?: IconName;
  shouldShowMintedModal?: boolean;
  isExpired?: boolean;
  customMintButton?: () => React.ReactNode;
};
export const zkTokenMap: Record<MINT_TYPES, ZkToken> = {
  [MINT_TYPES.zkBAB]: {
    title: MINT_TYPES.zkBAB,
    category: ZK_CATERGORY.zkKYC,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkBAB-570.jpeg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkBAB-570.jpeg',
    content: zkBABContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: null
  },
  [MINT_TYPES.zkGalxe]: {
    title: 'zkGalxe Passport',
    category: ZK_CATERGORY.zkKYC,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Galxe-zkPassport-FINAL.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zk-passport.jpeg',
    content: zkGalxeContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: null
  },
  [MINT_TYPES.zkArbAirdrop]: {
    title: 'ARB Early Supporter zkPass',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/ArbEarlySupporterzkPass-FINAL.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkArbitrumAirdrop-1.jpeg',
    content: zkAirContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        The ARB Early Supporter zkPass lets Arbitrum community members who have
        received the Arbitrum Airdrop to prove, in a privacy-preserving manner,
        that they have received an airdrop and have been a long-term supporter
        of Arbitrum. By using this zkPass, users do not have to reveal any
        identifiable information such as wallet addresses (or the contents of
        their wallet) or personal information.
      </div>
    )
  },
  [MINT_TYPES.zkReadON]: {
    title: 'ReadOn zkPass',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/AIGCPass1-FINAL.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/readon-main.jpg',
    content: zkReadONContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        The ReadON zkPass offers a privacy-enhanced version of the ReadON Pass,
        powered by ZK. By proving their ownership and eligibility using this
        ReadON zkPass, users can also participate in the ReadON ecosystem
        without revealing their personal information or wallet address.
      </div>
    )
  },
  [MINT_TYPES.zkLinea]: {
    title: 'Linea zkPioneer-Bridge week',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Linea-Bridge-Week2.jpg',
    imgUrlHorizontal:
      'https://npo-cdn.asmatch.xyz/Linea-Bridge-Week-Linea2.jpg',
    content: zkLineaBridgeContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Linea to launch Linea zkPioneer-Bridge week for
        Linea community members in recognition of briding assets from other
        chains to Linea. Linea zkPioneer-Bridge week allows Linea community
        members to have a zkDID when enjoying Web3 products and experiences. By
        minting a Linea zkPioneer, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address.
      </div>
    )
  },
  [MINT_TYPES.zkCyberConnect]: {
    title: 'CyberConnect zkProfile',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/cyberconnect-FINAL.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/cyberconnect-banner.jpeg',
    content: zkCyberConnectContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Cyberconnect to launch Cyberconnect zkProfile
        for Cyberconnect users: Hold Cyberconnect profile & 10+ W3STs.
        Cyberconnect zkProfile allows Cyberconnect users to have a zkDID when
        enjoying Web3 products and experiences. By minting a Cyberconnect
        zkProfile, you will be able to use it in Manta ecosystem projects with
        privacy, and, furthermore, with your long-time-built valuable identity
        without disclosing your exact address.
      </div>
    )
  },
  [MINT_TYPES.zkLineaNFT]: {
    title: 'Linea zkPioneer-NFT week',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Linea-NFT-Week-banner2.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/Linea-NFT-week2.jpg',
    content: zkLineaNFTContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Linea to launch Linea zkPioneer-NFT week for
        Linea community members in recognition of being a part of Linea NFT
        community .Linea zkPioneer-NFT week allows Linea community members to
        have a zkDID when enjoying Web3 products and experiences. By minting a
        Linea zkPioneer, you will be able to use it in Manta ecosystem projects
        with privacy, and, furthermore, with your long-time-built valuable
        identity without disclosing your exact address.
      </div>
    )
  },
  [MINT_TYPES.zkUltiverse]: {
    title: 'Ultiverse zkGuardian',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Ultiverse-zkGuardian-square.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/Ultiverse-banner.jpg',
    content: zkUltiverseContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Ulitverse to launch Ultiverse zkGuardian for
        Ultiverse community members: Meta Merge Pets & Ultiverse-Moonlight & OR
        ElectricSheep NFT holders. Ultiverse zkGuardian allows Ultiverse
        community members to have a zkDID when enjoying Web3 products and
        experiences. By minting a Ultiverse zkGuardian, you will be able to use
        it in Manta ecosystem projects with privacy, and, furthermore, with your
        long-time-built valuable identity without disclosing your exact address.
      </div>
    )
  },
  [MINT_TYPES.zkTaskon]: {
    title: 'Taskon zkElite',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/TaskON_zkElite_square.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/TaskON_zkElite_banner.jpg',
    content: zkTaskonContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Taskon to launch Taskon zkElite for Taskon
        community members in recognition of participating CAP campaigns on
        Taskon. Taskon zkElite allows Taskon community members to have a zkDID
        when enjoying Web3 products and experiences. By minting a Taskon
        zkElite, you will be able to use it in Manta ecosystem projects with
        privacy, and, furthermore, with your long-time-built valuable identity
        without disclosing your exact address. With Manta's zk innovation-proof
        key, Manta's zkSBT will be able to integrate with any mobile application
        in Web2 & Web3 and also integrate with any chain and ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkFrontier]: {
    title: 'Cradles zkFrontier',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Cradles-zkFrontier-square.jpg',
    imgUrlHorizontal:
      'https://npo-cdn.asmatch.xyz/Cradles-zkFrontier-banner.jpg',
    content: zkFrontierContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    isExpired: true,
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Cradles to launch Cradles zkFrontier for
        Cradles community members and Manta community members. Cradles
        zkFrontier allows Cradles community members to have a zkDID when
        enjoying Web3 products and experiences. By minting a Cradles zkFrontier,
        you will be able to use it in Manta ecosystem projects with privacy,
        and, furthermore, with your long-time-built valuable identity without
        disclosing your exact address. With Manta's zk innovation-proof key,
        Manta's zkSBT will be able to integrate with any mobile application in
        Web2 & Web3 and also integrate with any chain and ecosystem. Click here
        to check if you are eligible for this minting event with a Cradles
        campaign OAT or Manta Gaint squid NFT.
      </div>
    )
  },
  [MINT_TYPES.zkFuturist]: {
    title: 'QuestN zkFuturist',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/QuestN-zkFuturist.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/Cradles-manta.jpg',
    content: zkFuturistContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with QuestN to launchQuestN zkFuturist for QuestN
        community members. QuestN zkFuturist allows QuestN community members to
        have a zkDID when enjoying Web3 products and experiences. By minting a
        QuestN zkFuturist, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address. With Manta's zk
        innovation-proof key, Manta's zkSBT will be able to integrate with any
        mobile application in Web2 & Web3 and also integrate with any chain and
        ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkCertificate]: {
    title: 'POMP zkCertificate',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/POMP-detail.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/POMP.jpg',
    content: zkCertificateContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    shouldShowMintedModal: false,
    customMintButton() {
      return (
        <a
          href="https://atlantic.pomp.money/"
          target="_blank"
          rel="noreferrer"
          className="mt-5 flex justify-center items-center gradient-button text-white font-red-hat-text text-sm h-10 w-40 cursor-pointer rounded-lg hover:text-white">
          Enter
        </a>
      );
    },
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with POMP to launch POMP zkCertificate for POMP
        community members. POMP zkCertificate allows POMP community members to
        have a zkDID when enjoying Web3 products and experiences. By minting a
        POMP zkCertificate, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address. With Manta's zk
        innovation-proof key, Manta's zkSBT will be able to integrate with any
        mobile application in Web2 & Web3 and also integrate with any chain and
        ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkCertificateNFT]: {
    title: 'POMP NFT zkCertificate',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/azuki-01.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/azuki-banner.jpg',
    content: zkCertificateNFTContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    shouldShowMintedModal: false,
    customMintButton() {
      return (
        <a
          href="https://atlantic.pomp.money/"
          target="_blank"
          rel="noreferrer"
          className="mt-5 flex justify-center items-center gradient-button text-white font-red-hat-text text-sm h-10 w-40 cursor-pointer rounded-lg hover:text-white">
          Enter
        </a>
      );
    },
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with POMP to launch POMP NFT zkCertificate for POMP
        community members. POMP NFT zkCertificate allows POMP community members
        to have a zkDID when enjoying Web3 products and experiences. By minting
        a POMP NFT zkCertificate, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address. With Manta's zk
        innovation-proof key, Manta's zkSBT will be able to integrate with any
        mobile application in Web2 & Web3 and also integrate with any chain and
        ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkOP]: {
    title: 'OP Early Supporter zkPass',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkOp.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/POMP-zkOp.jpg',
    content: zkOPContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is launching Optimism early supporter zkPass for Optimism
        community members. Optimism early supporter zkPass allows Optimism
        community members to have a zkDID when enjoying Web3 products and
        experiences. By minting an Optimism early supporter zkPass, you will be
        able to use it in Manta ecosystem projects with privacy, and,
        furthermore, with your long-time-built valuable identity without
        disclosing your exact address. With Manta's proof key, Manta's zkSBT can
        integrate with any mobile application in Web2 & Web3 and also integrate
        with any chain and ecosystem. Who is eligible: You will be eligible to
        this minting event if you received airdrop from Optimism.
      </div>
    )
  },
  [MINT_TYPES.zkGetaverse]: {
    title: 'Getaverse zkInitiator',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/getaverse.jpeg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/getaverse1.jpeg',
    content: zkGetaverseContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Getaverse to launch Getaverse zkInitiator for
        Getaverse community members. Getaverse zkInitiator allows Getaverse
        community members to have a zkDID when enjoying Web3 products and
        experiences. By minting a Getaverse zkInitiator, you will be able to use
        it in Manta ecosystem projects with privacy, and, furthermore, with your
        long-time-built valuable identity without disclosing your exact address.
        With Manta's zk innovation-proof key, Manta's zkSBT will be able to
        integrate with any mobile application in Web2 & Web3 and also integrate
        with any chain and ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkKaratDao]: {
    title: 'KaratDAO zkBuns',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkbuns.jpeg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkbuns1.jpeg',
    content: zkKaratDaoContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with KaratDAO to launch KaratDAO zkBunsr for
        KaratDAO community members. KaratDAO zkBuns allows KaratDAO community
        members to have a zkDID when enjoying Web3 products and experiences. By
        minting a KaratDAO zkBuns, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address. With Manta's zk
        innovation-proof key, Manta's zkSBT will be able to integrate with any
        mobile application in Web2 & Web3 and also integrate with any chain and
        ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkBurgerCities]: {
    title: 'BurgerCities zkBurger',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkburger2.jpeg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkburger.jpeg',
    content: zkBurgerCitiesContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with BurgerCities to launch zkburger for
        BurgerCities community members. zkBurger allows BurgerCities community
        members to have a zkDID when enjoying Web3 products and experiences. By
        minting a zkBurger, you will be able to use it in Manta ecosystem
        projects with privacy, and, furthermore, with your long-time-built
        valuable identity without disclosing your exact address. With Manta's zk
        innovation-proof key, Manta's zkSBT will be able to integrate with any
        mobile application in Web2 & Web3 and also integrate with any chain and
        ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkBladeDao]: {
    title: 'BladeDAO zkBlade',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/BladeDAO2.png',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/BladeDAO.jpg',
    content: zkBladeDaoContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
      Manta is partnering with BladeDAO to launch BladeDAO zkBlade for BladeDAO community members. BladeDAO zkBlade allows BladeDAO members to have a zkDID when enjoying Web3 products and experiences. By minting a BladeDAO zkBlade, you will be able to use it in Manta ecosystem projects with privacy, and, furthermore, with your long-time-built valuable identity without disclosing your exact address. With Manta's zk innovation-proof key, Manta's zkSBT will be able to integrate with any mobile application in Web2 & Web3 and also integrate with any chain and ecosystem.
      </div>
    )
  }
};

export const zkCredentialsProjectTypes = Object.entries(zkTokenMap)
  .filter((item) => item[1].category === ZK_CATERGORY.zkCredential)
  .map((item) => item[0])
  .reverse() as MINT_TYPES[];

export const zkKYCProjectTypes = Object.entries(zkTokenMap)
  .filter((item) => item[1].category === ZK_CATERGORY.zkKYC)
  .map((item) => item[0])
  .reverse() as MINT_TYPES[];
