import {
  ButtonHTMLAttributes,
  useMemo,
  MouseEvent,
  ReactNode,
  useEffect
} from 'react';

import ConnectWalletModal from 'components/Modal/connectWalletModal';
import { useModal } from 'hooks';
import { useMantaWallet } from 'contexts/mantaWalletContext';

type ButtonWithWalletProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
> & {
  btnComponent: ReactNode;
  noWalletComponent?: ReactNode;
  disabledCls?: string;
};
const ButtonWithWallet = (props: ButtonWithWalletProps) => {
  const {
    onClick,
    btnComponent,
    noWalletComponent = 'Connect Wallet',
    disabled,
    disabledCls = 'brightness-50 cursor-not-allowed',
    className,
    ...otherProps
  } = props;

  const {
    ModalWrapper: WalletModalWrapper,
    hideModal: hideWalletModal,
    showModal: showWalletModal
  } = useModal();

  const { externalAccount } = useMantaWallet();

  useEffect(() => {
    const closeModelCheck = () => {
      if (externalAccount?.address) {
        hideWalletModal();
      }
    };
    closeModelCheck();
  }, [externalAccount?.address, hideWalletModal]);

  const handleClickBtn = (e: MouseEvent<HTMLButtonElement>) => {
    if (!externalAccount) {
      showWalletModal();
      return;
    }
    onClick && onClick(e);
  };

  const finalChildren = useMemo(() => {
    if (!externalAccount) {
      return noWalletComponent;
    }
    return btnComponent;
  }, [btnComponent, externalAccount, noWalletComponent]);

  const finalDisabled = !!externalAccount && disabled;
  const finalDisabledCls = finalDisabled ? disabledCls : '';

  return (
    <>
      <button
        onClick={handleClickBtn}
        disabled={finalDisabled}
        {...otherProps}
        className={`${className} ${finalDisabledCls}`}>
        {finalChildren}
      </button>
      <WalletModalWrapper>
        <ConnectWalletModal hideModal={hideWalletModal} />
      </WalletModalWrapper>
    </>
  );
};

export default ButtonWithWallet;
