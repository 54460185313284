import classNames from 'classnames';
import Icon from 'components/Icon';
import { useState } from 'react';
import ButtonWithWallet from '../ButtonWithWallet';

const CreateProofKeyModalContent = ({
  lastProject,
  tags,
  handleCreate
}: {
  lastProject: string;
  tags: string[];
  handleCreate: (tag: string) => void;
}) => {
  const [tag, setTag] = useState('');
  const exist = tag && (lastProject === tag || tags.includes(tag));

  const handleChange = (tag: string) => {
    setTag(tag);
  };

  return (
    <>
      <span className="absolute z-10 top-5 left-6 text-white text-xl">
        Create a New Proof Key
      </span>
      <div className="mt-12 p-6 flex flex-row text-white">
        <div className="mr-8 flex w-28 h-28 bg-white bg-opacity-5 rounded-xl">
          <Icon className="m-auto" name="fingerPrint" />
        </div>
        <div className="flex flex-col">
          <input
            id="tagName"
            className={classNames(
              'pl-6 h-11 px-6 py-2 font-red-hat-text text-sm placeholder-gray-500 text-black dark:text-white bg-white bg-opacity-5 border border-white-light rounded-lg'
            )}
            placeholder="Enter Tag Name"
            autoComplete="off"
            value={tag}
            onChange={(e) => handleChange(e.target.value)}
          />
          <div className="flex flex-row my-1 h-5">
            {!!exist && (
              <>
                <Icon name="information" className="mr-2 text-error" />
                <div className="text-error text-sm">Already Exist</div>
              </>
            )}
          </div>
          <ButtonWithWallet
            onClick={() => handleCreate(tag)}
            disabled={!!exist}
            btnComponent="Create"
            className="mr-4 w-full bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 cursor-pointer rounded-lg"
            disabledCls="bg-opacity-50 cursor-not-allowed"
          />
        </div>
      </div>
    </>
  );
};

export default CreateProofKeyModalContent;
