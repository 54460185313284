export enum WalletErrorCode {
  rejected = 4001,
  locked = 500
}

export enum RelayerReturnedCode {
  TRANSACTION_FEE_TOO_HIGH = -6,
  SIGNATURE_MATCH_FAILED,
  SERVER_ERROR,
  INVALID_PARAMS,
  ADDRESS_IS_MINTED,
  ADDRESS_NOT_EXISTS,
  OK
}
