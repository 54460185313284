import { WalletLogoProps } from 'manta-extension-connect';

import Icon from 'components/Icon';
import { useKeyring } from 'contexts/keyringContext';
import { getSubstrateWallets } from 'utils';
import getWalletDisplayName from 'utils/display/getWalletDisplayName';

const WalletNotInstalledBlock = ({
  walletName,
  walletLogo,
  walletInstallLink
}: {
  walletName: string;
  walletLogo: WalletLogoProps;
  walletInstallLink: string;
}) => {
  return (
    <div className="mt-6 py-3 px-4 h-16 text-sm flex items-center justify-between border border-white-light text-white rounded-lg w-full block">
      <div className="flex flex-row items-center gap-4">
        {walletLogo && typeof walletLogo === 'object' ? (
          <img
            src={walletLogo.src}
            alt={walletLogo.alt}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <Icon name={walletLogo} className="w-6 h-6 rounded-full" />
        )}
        {walletName}
      </div>
      <a href={walletInstallLink} target="_blank" rel="noreferrer">
        <div className="text-center rounded-lg bg-button-fourth text-white py-2 px-4 text-sm w-21">
          Install
        </div>
      </a>
    </div>
  );
};

const WalletInstalledBlock = ({
  walletName,
  walletLogo,
  connectHandler
}: {
  walletName: string;
  walletLogo: WalletLogoProps;
  connectHandler: () => Promise<void>;
}) => {
  return (
    <button
      onClick={connectHandler}
      className="relative mt-6 py-3 px-4 h-16 flex items-center justify-between border border-white-light text-white rounded-lg w-full block">
      <div className="flex flex-row items-center gap-4 font-red-hat-mono text-sm">
        {walletLogo && typeof walletLogo === 'object' ? (
          <img
            src={walletLogo.src}
            alt={walletLogo.alt}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <Icon name={walletLogo} className="w-6 h-6 rounded-full" />
        )}
        {walletName}
      </div>
      <div className="bg-connect-wallet-button rounded-lg bg-button-fourth text-white py-2 px-4 text-sm">
        Connect
      </div>
    </button>
  );
};

const WalletEnabledBlock = ({
  walletName,
  walletLogo
}: {
  walletName: string;
  walletLogo: WalletLogoProps;
}) => {
  return (
    <div className="mt-6 py-3 px-4 h-16 flex items-center justify-between border border-white-light text-white rounded-lg w-full block">
      <div className="flex flex-row items-center gap-4">
        {walletLogo && typeof walletLogo === 'object' ? (
          <img
            src={walletLogo.src}
            alt={walletLogo.alt}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <Icon name={walletLogo} className="w-6 h-6 rounded-full" />
        )}

        {walletName}
      </div>
      <div className="flex flex-row gap-3 items-center rounded-lg text-xs">
        <div className="rounded full w-2 h-2 bg-green-300"></div>Connected
      </div>
    </div>
  );
};

const ConnectWalletBlock = ({
  walletName,
  isWalletInstalled,
  walletInstallLink,
  walletLogo,
  isWalletEnabled,
  connectHandler
}: {
  walletName: string;
  isWalletInstalled: boolean;
  walletInstallLink: string;
  walletLogo: WalletLogoProps;
  isWalletEnabled: boolean;
  connectHandler: () => Promise<void>;
}) => {
  if (isWalletEnabled) {
    return (
      <WalletEnabledBlock walletName={walletName} walletLogo={walletLogo} />
    );
  } else if (isWalletInstalled) {
    return (
      <WalletInstalledBlock
        walletName={walletName}
        walletLogo={walletLogo}
        connectHandler={connectHandler}
      />
    );
  } else {
    return (
      <WalletNotInstalledBlock
        walletName={walletName}
        walletLogo={walletLogo}
        walletInstallLink={walletInstallLink}
      />
    );
  }
};

export const SubstrateConnectWalletBlock = ({
  hideModal
}: {
  hideModal: () => void;
}) => {
  const { connectWallet, connectWalletExtension } = useKeyring();
  const substrateWallets = getSubstrateWallets();

  const handleConnectWallet = (walletName: string) => async () => {
    connectWalletExtension(walletName);
    const isConnected = await connectWallet(walletName);
    if (isConnected) {
      hideModal();
    }
  };

  return (
    // fix Component cannot be used as a JSX component. Its return type 'Element[]' is not a valid JSX element
    // see https://stackoverflow.com/questions/62702485/component-cannot-be-used-as-a-jsx-component-its-return-type-element-is-not
    <>
      {substrateWallets.map((wallet) => {
        // wallet.extension would not be defined if enabled not called
        const isWalletEnabled = wallet.extension ? true : false;
        return (
          <ConnectWalletBlock
            key={wallet.extensionName}
            walletName={getWalletDisplayName(wallet.extensionName)}
            isWalletInstalled={wallet.installed ?? false}
            walletInstallLink={wallet.installUrl}
            walletLogo={wallet.logo}
            isWalletEnabled={isWalletEnabled}
            connectHandler={handleConnectWallet(wallet.extensionName)}
          />
        );
      })}
    </>
  );
};

const ConnectWalletModal = ({ hideModal }: { hideModal: () => void }) => {
  return (
    <div className="w-91 text-white">
      <h1 className="text-xl">Connect Manta Wallet</h1>
      <p className="text-sm mb-6 mt-6 text-white text-opacity-60 font-red-hat-text mr-14">
        The Manta Wallet allows you to manage all of your public and private
        assets in one place, including your zkBABs.
      </p>
      <SubstrateConnectWalletBlock hideModal={hideModal} />
      <p className="flex flex-row gap-2 mt-5 text-secondary text-xsss">
        <Icon name="information" />
        Already installed? Try refreshing this page
      </p>
    </div>
  );
};

export default ConnectWalletModal;
