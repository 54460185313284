import { SbtTokenContextProvider } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useParams } from 'react-router-dom';
import { randomArray } from 'utils/array/randomArray';
import { onGoingProjectTypes } from '../Home/ProjectConstant';
import SbtToken from '../Home/SbtToken';
import { MINT_TYPES } from '../Home/type';
import OnGoingProjects from '../OngoingProjects';

const ProjectDetail = () => {
  const params = useParams();
  const tokenType = params.projectType as MINT_TYPES;
  const randomProjectList = randomArray(
    onGoingProjectTypes.filter((type) => type != tokenType)
  ).slice(0, 4);

  return (
    <div className="w-75 mx-auto">
      <SbtTokenContextProvider tokenType={tokenType}>
        <SbtToken tokenType={params.projectType as MINT_TYPES} />
      </SbtTokenContextProvider>
      <OnGoingProjects
        title="You might also like"
        projectList={randomProjectList}
        gridClassName="grid-cols-5 gap-6"
        className="py-6"
      />
    </div>
  );
};
export default ProjectDetail;
