import { useEffect, useMemo } from 'react';

import Icon from 'components/Icon';
import {
  useSbtToken,
  VALID_STATUS
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import getAbbreviatedName from 'utils/display/getAbbreviatedName';
import mintVerify from 'resources/images/sbt/mint-verify.png';
import { useModal } from 'hooks';
import EVMWalletModal, {
  iconNames,
  WalletType
} from 'components/Modal/EVMWalletModal';
import { useAccount, useDisconnect } from 'wagmi';
import { Popover } from 'element-react';

export const DisconnectComponent = () => {
  const { address } = useAccount();
  const { disconnectAsync } = useDisconnect();
  return (
    <div className="px-4 py-2">
      <p className="text-white text-opacity-80 text-xss break-all">
        You are connected as {address}
      </p>
      <button
        className="text-white mt-1 border border-white rounded-lg px-4 py-1"
        onClick={() => disconnectAsync()}>
        Disconnect
      </button>
    </div>
  );
};

const TokenValidation = ({
  successMsg,
  errorMsg
}: {
  successMsg: string;
  errorMsg: string;
}) => {
  const { validateToken, tokenValidStatus } = useSbtToken();
  const { ModalWrapper, showModal, hideModal } = useModal();
  const { address, connector, isReconnecting } = useAccount();

  useEffect(() => {
    if (address) {
      validateToken();
    }
  }, [address, validateToken]);

  const msg = useMemo(() => {
    if (tokenValidStatus === VALID_STATUS.networkError) {
      return 'Network Error';
    }
    if (tokenValidStatus === VALID_STATUS.noSbtInEvmAddress) {
      return errorMsg;
    }
    return 'Failed adding to allowedList';
  }, [tokenValidStatus, errorMsg]);

  if (!address) {
    return (
      <>
        <div
          onClick={() => {
            showModal();
          }}
          className="w-44 h-10 flex rounded-lg items-center justify-center text-white text-xsss font-red-hat-mono border cursor-pointer hover:opacity-70">
          <img className="mr-4" src={mintVerify} />
          <span>Verify Holder</span>
        </div>
        <ModalWrapper>
          <EVMWalletModal hideModal={hideModal} />
        </ModalWrapper>
      </>
    );
  }
  if (tokenValidStatus === VALID_STATUS.default) {
    return (
      <button className="text-sm flex items-center border rounded-lg py-1.5 px-4 cursor-not-allowed font-red-hat-mono">
        Detecting...
      </button>
    );
  }
  if (tokenValidStatus === VALID_STATUS.success) {
    return (
      <div className="inline-flex items-center bg-checked bg-opacity-20 px-4 h-10 rounded-lg text-checked">
        <Icon name="greenCheck" />
        <Icon
          name={iconNames[connector?.name as WalletType]}
          className="mx-2 w-4 h-4"
        />
        <h3 className="text-sm mr-2  font-semibold">{successMsg}</h3>
        <p className="text-white text-xsss">
          Connected as {getAbbreviatedName(address, 4, 4)}
        </p>
        <Popover
          trigger="hover"
          placement="right"
          content={<DisconnectComponent />}
          width="177"
          className="disconnect-pop">
          <div>
            <Icon name="information" className="w-2.5 h-2.5 text-white ml-2" />
          </div>
        </Popover>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap items-center gap-4">
      <button
        className="text-sm flex items-center border rounded-lg py-1.5 px-4 font-red-hat-mono"
        onClick={() => {
          showModal();
        }}>
        {connector?.name && (
          <Icon
            name={iconNames[connector.name as WalletType]}
            className="mr-3.5 w-6 h-6"
          />
        )}
        Switch to another address
      </button>
      <div className="inline-flex items-center bg-error bg-opacity-20 px-4 h-10 rounded-lg text-error">
        <Icon name="information" />
        <h3 className="text-sm mx-2 font-semibold">{msg}</h3>
        <p className="text-white text-xsss font-red-hat-mono">
          Connected as {getAbbreviatedName(address, 4, 4)}
        </p>
      </div>
      <ModalWrapper>
        <EVMWalletModal hideModal={hideModal} />
      </ModalWrapper>
    </div>
  );
};

export default TokenValidation;
