import { useState } from 'react';

import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useModal } from 'hooks';
import isEmpty from 'lodash.isempty';
import { GeneratedImg } from 'pages/SBTPage/SBTContext';
import {
  Project,
  useMintedList
} from 'pages/SBTPage/SBTContext/mintedListContext';
import ConfirmModalContent from '../ConfirmModal';
import CreateProofKeyModalContent from '../CreateProofKeyModal';
import ProofDetailModal from '../ProofKeyModal';
import SearchProofKeyModalContent from '../SearchProofKeyModal';

const MintedImg = ({
  blur_url,
  url,
  proofId = '',
  style,
  assetId,
  asset_id = '',
  token_type = ''
}: GeneratedImg) => {
  const { privateWallet } = useMantaWallet();
  const [currentTag, setCurrentTag] = useState('');
  const [tags, setTags] = useState(['']);
  const [project, setProject] = useState({ project: '' } as Project); // proof detail
  const [lastProject, setLastProject] = useState('');

  const {
    ModalWrapper: SearchProofKeyModalWrapper,
    showModal: showSearchProofKeyModal,
    hideModal: hideSearchProofKeyModal
  } = useModal({
    outLineCss: 'relative inline-block bg-fourth rounded-xl text-black',
    closeDisabled: false
  });
  const {
    ModalWrapper: CreateProofKeyModalWrapper,
    showModal: showCreateProofKeyModal,
    hideModal: hideCreateProofKeyModal
  } = useModal({
    outLineCss: 'relative inline-block bg-fourth rounded-xl text-black',
    closeDisabled: false
  });
  const {
    ModalWrapper: ProofDetailModalWrapper,
    showModal: showProofDetailModal,
    hideModal: hideProofDetailModal
  } = useModal({
    outLineCss: 'relative inline-block bg-fourth rounded-xl text-black',
    closeDisabled: false
  });
  const {
    ModalWrapper: ConfirmModalWrapper,
    showModal: showConfirmModal,
    hideModal: hideConfirmModal
  } = useModal({
    outLineCss: 'relative inline-block bg-fourth rounded-xl text-black',
    closeDisabled: false
  });
  const {
    createProofTag,
    deleteProofKey,
    getProofKey,
    updateProofKey,
    getProofKeyTags
  } = useMintedList();

  const getProject = async (tag: string) => {
    const project = await getProofKey(asset_id, tag, token_type);
    const exist = !isEmpty(project);
    exist && setProject(project);
    return exist;
  };

  const handleShowSearch = async () => {
    const tags = await getProofKeyTags(asset_id, token_type);
    if (!tags) return;
    setTags(tags);
    showSearchProofKeyModal();
  };

  const handleCreateNew = async () => {
    hideSearchProofKeyModal();
    showCreateProofKeyModal();
  };

  const handleCreateProofTag = async (tag: string) => {
    const res = await createProofTag(proofId, tag, asset_id, token_type);
    const { valid, project = '', message = '' } = res || {};
    if (!valid && message === 'Proof key and tag exist!') {
      setLastProject(project);
    }
    hideCreateProofKeyModal();
  };

  const handleShowProofDetail = async (tag: string) => {
    setCurrentTag(tag);
    const success = await getProject(tag);
    if (!success) return;
    hideSearchProofKeyModal();
    showProofDetailModal();
  };

  const handleConfirmDelete = () => {
    hideProofDetailModal();
    showConfirmModal();
  };

  const handleDelete = async () => {
    const success = await deleteProofKey(currentTag, asset_id, token_type);
    if (success) hideConfirmModal();
  };

  const handleBack = () => {
    hideCreateProofKeyModal();
    hideProofDetailModal();
    showSearchProofKeyModal();
  };

  const handleCancel = () => {
    setLastProject('');
    hideSearchProofKeyModal();
    hideCreateProofKeyModal();
    hideProofDetailModal();
    hideConfirmModal();
  };

  const handleEdit = async (tag: string) => {
    const old_tag = project?.project || '';
    const success = await updateProofKey(tag, old_tag, asset_id, token_type);
    if (success) {
      setProject({ ...project, project: tag });
      const newTags = tags.map((t) => (t === old_tag ? tag : t));
      setTags(newTags);
    }
  };

  return (
    <div className="relative w-max bg-primary unselectable-text mr-4">
      <img src={blur_url ?? url} className="rounded-t-lg w-44 h-44 img-bg" />
      <div className="absolute bottom-12 w-full flex px-2 justify-between items-end">
        <span className="text-white text-sm font-red-hat-mono font-bold">
          {style || assetId}
        </span>
      </div>
      <div
        className="px-2 py-2 pt-2.5 flex items-center w-44 bg-white bg-opacity-5 rounded-b-lg justify-between text-sm unselectable-text cursor-pointer"
        onClick={() => handleShowSearch()}>
        <p className="text-white text-opacity-80 m-auto">Manage Proof Key</p>
      </div>

      <SearchProofKeyModalWrapper>
        <SearchProofKeyModalContent
          tags={tags}
          handleCreateNew={handleCreateNew}
          handleShowProofDetail={(tag) => handleShowProofDetail(tag)}
        />
      </SearchProofKeyModalWrapper>

      <CreateProofKeyModalWrapper>
        <CreateProofKeyModalContent
          lastProject={lastProject}
          tags={tags}
          handleCreate={(tag) => handleCreateProofTag(tag)}
        />
      </CreateProofKeyModalWrapper>

      <ProofDetailModalWrapper>
        <ProofDetailModal
          project={project}
          token_type={token_type || ''}
          url={url}
          handleDelete={handleConfirmDelete}
          handleBack={handleBack}
          handleEdit={handleEdit}
        />
      </ProofDetailModalWrapper>

      <ConfirmModalWrapper>
        <ConfirmModalContent
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      </ConfirmModalWrapper>
    </div>
  );
};
export default MintedImg;
