import Icon from 'components/Icon';

const AboutNPO = () => (
  <div className="mt-12 w-full">
    <h1 className="text-2xl font-semibold">About NPO</h1>
    <div className="flex mt-4 gap-5">
      <div
        onClick={() =>
          window.open(
            'https://mantanetwork.medium.com/introducing-zksbts-zknfts-and-npo-89a8e6d77a7f',
            '_blank',
            'noopener,noreferrer'
          )
        }
        className="bg-white bg-opacity-5 rounded-lg p-6 flex-grow cursor-pointer">
        <h2 className="text-2xl">NFT Private Offering</h2>
        <div className="flex items-center text-white mt-2 mb-3">
          Learn more about NPO
          <Icon name="activityRightArrow" className="w-3.5 h-3.5 ml-2" />
        </div>
        <p className="text-sm text-white text-opacity-60 leading-4.5">
          NPO is a cutting-edge zkNFT launchpad developed under MantaPay by
          Manta.Network, which allows projects to privately launch and
          distribute their NFTs. By leveraging zero-knowledge (zk) technology,
          NPO ensures that NFT launches are secure, private, and efficient. We
          have already established partnerships with several projects in the
          GameFi/SocialFi and NFT industries, indicating its potential to become
          a trusted and reliable platform for NFT distribution.
        </p>
      </div>
      <div
        onClick={() =>
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeLBq8-1re37mqj3XAPjYyEYF1kxObpDL0V4j6T-Q20uHpj8Q/viewform',
            '_blank',
            'noopener,noreferrer'
          )
        }
        className="bg-white bg-opacity-5 rounded-lg p-6 w-96 flex-none cursor-pointer">
        <h2 className="text-2xl">Met Problems?</h2>
        <div className="flex items-center text-white mt-2 mb-3 font-red-hat-text">
          We are here to help
          <Icon name="activityRightArrow" className="w-3.5 h-3.5 ml-2" />
        </div>
      </div>
    </div>
  </div>
);

export default AboutNPO;
