// Given a wallet name as recognized by @talisman/connect-wallets library,

import WALLET_NAME from 'constants/WalletConstants';

// returns the appropriate display name for the wallet.
const getWalletDisplayName = (name: string) => {
  if (name === WALLET_NAME.MANTAWALLET) {
    return 'Manta Wallet';
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
};
export default getWalletDisplayName;
