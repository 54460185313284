import Icon from 'components/Icon';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useModal } from 'hooks';
import { useSbtCommon } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import {
  useSbtToken,
  VALID_STATUS
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ButtonWithWallet from '../ButtonWithWallet';
import SbtTokenMintCheckModal from '../SbtTokenMintCheckModal';
import SbtTokenMintedModal from '../SbtTokenMintedModal';
import TokenValidation from '../TokenValidation';
import MintButton from './MintButton';
import { zkTokenMap } from './ProjectConstant';
import { MINT_TYPES } from './type';

export interface SbtTokenHorizontalProps {
  tokenType: MINT_TYPES;
}

const SbtTokenHorizontal = ({ tokenType }: SbtTokenHorizontalProps) => {
  const {
    title,
    contentHorizontal,
    imgUrlHorizontal,
    detectedMsg,
    noSbtInEvmAddressMsg,
    logo1,
    logo2,
    shouldShowMintedModal = true,
    isExpired,
    customMintButton
  } = zkTokenMap[tokenType];

  const network = useConfig().NETWORK_NAME.toLowerCase();
  const { tokenValidStatus } = useSbtToken();
  const { tokenCountList } = useSbtCommon();
  const { externalAccount } = useMantaWallet();
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeOnBackdropClick: false
  });
  const { ModalWrapper: MintedModalWrapper, showModal: showMintedModal } =
    useModal({
      closeOnBackdropClick: false
    });

  const totalMinted = useMemo(() => {
    let currentCount = 0;
    for (const { token, count } of tokenCountList) {
      if (token === tokenType) {
        currentCount = count;
        break;
      }
    }
    return currentCount;
  }, [tokenType, tokenCountList]);

  return (
    <div className="flex bg-white bg-opacity-5 rounded-md">
      <div className="relative">
        <div className="m-auto h-129 w-229 flex flex-col justify-center items-center font-red-hat-mono">
          <img src={imgUrlHorizontal} className="img-bg" />
        </div>
        {shouldShowMintedModal && (
          <div className="text-white text-opacity-80 mt-4 text-sm absolute bottom-6 left-32 select-none">
            Total Minted: {totalMinted}
          </div>
        )}
      </div>
      <div className="ml-5 relative pt-6 pl-5">
        <div className="flex items-center pb-3.5 text-2xl">
          {title}{' '}
          {logo1 && logo2 && (
            <div className="ml-5 flex items-center text-xss text-white bg-black px-3 py-0.5">
              <Icon name={logo1} className="mr-3" /> x{' '}
              <Icon name={logo2} className="ml-3" />
            </div>
          )}
        </div>
        {contentHorizontal}
        <Link
          to={`/${network}/sbt/projects/${tokenType}`}
          className="flex text-white hover:text-white items-center text-sm mt-4">
          Click to know more{' '}
          <Icon
            className="w-4 h-4 cursor-pointer text-white ml-2"
            name="activityRightArrow"
          />
        </Link>
        {customMintButton ? (
          customMintButton()
        ) : (
          <>
            {!isExpired ? (
              <>
                <div className="mt-3.5 text-2xl mb-4 font-semibold">
                  Free Mint
                </div>
                <div>
                  <TokenValidation
                    successMsg={detectedMsg}
                    errorMsg={noSbtInEvmAddressMsg}
                  />
                </div>
              </>
            ) : (
              <button className="mt-6 w-44 px-4 py-2 leading-5  border border-white border-opacity-50 rounded-md text-white text-opacity-50 cursor-not-allowed">
                Expired
              </button>
            )}
            {tokenValidStatus === VALID_STATUS.success && (
              <div>
                <ButtonWithWallet
                  btnComponent={
                    <div className="mt-4 flex items-center bg-checked bg-opacity-20 h-10 px-4 rounded-lg">
                      <Icon name="greenCheck" />
                      <p className="ml-4 text-checked text-sm">
                        Manta Wallet Connected
                      </p>
                    </div>
                  }
                  noWalletComponent={
                    <div className="mt-4 flex items-center gradient-button h-10 px-4 rounded-lg">
                      <Icon
                        name="singleColorManta"
                        className="w-5 h-5 text-white"
                      />
                      <h3 className="ml-4 text-white text-sm">
                        Connect to Manta Wallet
                      </h3>
                    </div>
                  }
                />
              </div>
            )}
            {tokenValidStatus === VALID_STATUS.success && externalAccount && (
              <div className="flex justify-between mt-4 absolute bottom-6">
                <MintButton showMintModal={showModal} leftToRight={true} />
              </div>
            )}
          </>
        )}
      </div>

      <ModalWrapper>
        <SbtTokenMintCheckModal
          hideModal={hideModal}
          showMintedModal={showMintedModal}
          title={title}
        />
      </ModalWrapper>
      <MintedModalWrapper>
        <SbtTokenMintedModal />
      </MintedModalWrapper>
    </div>
  );
};

export default SbtTokenHorizontal;
