import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import {
  EMPTY_MESSAGE,
  MintedMap,
  useMintedList
} from 'pages/SBTPage/SBTContext/mintedListContext';
import MintedImg from '../MintedImg';
import RestoreData from './RestoreData';

const MintedImgItem = ({
  date,
  mintedMap
}: {
  date: string;
  mintedMap: MintedMap;
}) => (
  <>
    {mintedMap[date].map((item, index) => {
      return (
        <div key={`${date}-${index}`} className="flex flex-col mt-4">
          {index === 0 && (
            <p className="mb-4 text-white text-opacity-60 text-sm font-red-mono-text">
              {dayjs(date).format('MMM DD，YYYY')}
            </p>
          )}

          <MintedImg
            {...item}
            key={`${date}-${index}`}
            proofId={item.proof_id}
            assetId={`#${item.asset_id}`}
          />
        </div>
      );
    })}
  </>
);

const MintedList = () => {
  const { mintedMap, loading, tipMessage, getMintedMap } = useMintedList();

  useEffect(() => {
    getMintedMap();
  }, [getMintedMap]);

  // display the minted list by date desc
  const orderedDates = useMemo(() => {
    return Object.keys(mintedMap).sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    });
  }, [mintedMap]);

  return (
    <div className="flex-1 flex flex-col mx-auto mb-8 rounded-xl p-6 w-75 relative">
      <div className="flex items-center">
        <Icon name="mantaLogo" className="w-8 h-8 mr-3" />
        <h2 className="text-xl font-red-hat-mono tracking-widest font-medium">
          My zkNFTs
        </h2>
      </div>
      <h1 className="text-3xl mt-4">Minted</h1>

      {loading && <DotLoader cls="flex-1 justify-center transform scale-200" />}
      {tipMessage && (
        <p className="text-white text-opacity-80 text-sm mt-3">{tipMessage}</p>
      )}
      {!loading && (tipMessage === EMPTY_MESSAGE || tipMessage === '') && (
        <RestoreData />
      )}

      {!loading && !tipMessage && (
        <div className="flex items-end flex-wrap">
          {orderedDates.map((date,index) => {
            return (
              <MintedImgItem date={date} key={`${date}-${index}`} mintedMap={mintedMap} />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MintedList;
