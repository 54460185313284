import { useEffect, useMemo, useState, useCallback } from 'react';

import DotLoader from 'components/Loaders/DotLoader';
import { useTxStatus } from 'contexts/txStatusContext';
import TxStatus from 'types/TxStatus';
import Icon from 'components/Icon';
import { usePublicBalances } from 'contexts/publicBalancesContext';
import { useSbtToken } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useUsdPrices } from 'contexts/usdPricesContext';
import Usd from 'types/Usd';
import Decimal from 'decimal.js';
import ButtonWithWallet from '../ButtonWithWallet';

const BtnComponent = ({ loading }: { loading: boolean }) => {
  return (
    <>
      Confirm
      {loading && <DotLoader />}
    </>
  );
};

const SbtTokenMintCheckModal = ({
  hideModal,
  showMintedModal,
  title,
  checkBalance = false
}: {
  hideModal: () => void;
  showMintedModal: () => void;
  title: string;
  checkBalance?: boolean;
}) => {
  const [loading, toggleLoading] = useState(false);

  const { txStatus, setTxStatus } = useTxStatus();
  const { getNativePublicBalance } = usePublicBalances();
  const { gasFee, mintSbtToken, saveMintData } = useSbtToken();
  const { nativeUsdPrice } = useUsdPrices();

  const nativePublicBalance = getNativePublicBalance();

  const mintSBTConfirm = useCallback(async () => {
    toggleLoading(true);
    try {
      await mintSbtToken();
    } catch (e) {
      console.error(e);
      setTxStatus(TxStatus.failed(''));
    }
  }, [setTxStatus, mintSbtToken]);

  useEffect(() => {
    const handleTxFinalized = () => {
      if (txStatus?.isFinalized()) {
        toggleLoading(false);
        hideModal();
        setTimeout(() => {
          saveMintData();
        });
      } else if (txStatus?.isFailed()) {
        toggleLoading(false);
      }
    };
    handleTxFinalized();
  }, [hideModal, showMintedModal, txStatus, saveMintData]);

  const errorMsg = useMemo(() => {
    if (loading) {
      return '';
    }
    // no need to check balance when manta cover the gas fee
    if (
      nativePublicBalance &&
      gasFee?.gt(nativePublicBalance) &&
      checkBalance
    ) {
      return 'Insufficient balance';
    }
    if (gasFee === null) {
      return 'Fail to get gas fee, please refresh the page';
    }
    if (nativePublicBalance == null) {
      return 'Fail to get balance, please refresh the page';
    }
    return '';
  }, [gasFee, loading, nativePublicBalance, checkBalance]);

  const disabled = useMemo(
    () => loading || !!errorMsg || gasFee == null,
    [errorMsg, loading, gasFee]
  );

  return (
    <div className="text-white w-128 text-center">
      <h2 className="text-2xl text-left font-bold">Checkout</h2>
      <div className="bg-secondary rounded-lg mt-6 mb-3">
        <div className="flex justify-between pt-4 px-4">
          <p>1 {title}</p>
          <span className="font-bold text-check">Free</span>
        </div>
        <div className="flex justify-between border-b border-split px-4 py-4">
          <p>Gas Fee</p>
          <span className="ml-auto text-opacity-60 text-white mr-2">
            + approximately
          </span>
          <span className="text-white text-right font-bold">
            {gasFee?.toDisplayString()}
          </span>
        </div>
        <div className="flex justify-between p-4">
          <p>Total</p>
          <div className="flex flex-col text-right">
            <span className="text-check font-bold">
              {gasFee?.toDisplayString()}
            </span>
            <span className="text-white text-opacity-60">
              {gasFee
                ?.toUsd(nativeUsdPrice ?? new Usd(new Decimal(0)))
                .toString()}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm text-left">
          Balance: {nativePublicBalance?.toDisplayString() ?? '-'}
        </p>
        {errorMsg && (
          <p className="text-error text-left">
            <Icon name="information" className="mr-2 inline-block" />
            {errorMsg}
          </p>
        )}
      </div>
      <ButtonWithWallet
        onClick={mintSBTConfirm}
        disabled={disabled}
        btnComponent={<BtnComponent loading={loading} />}
        className="px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter mt-12"
      />
    </div>
  );
};

export default SbtTokenMintCheckModal;
