// @ts-nocheck
import AppRouter from 'AppRouter';
import GlobalContexts from 'contexts/globalContexts';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleUnload = (e) => {
    localStorage.clear(); // TODO hack clear storage
  };

  return (
    <div className="main-app bg-primary">
      <div className="flex flex-col m-auto">
        <div className="min-h-screen">
          <GlobalContexts>
            <AppRouter />
          </GlobalContexts>
        </div>
      </div>
    </div>
  );
}

export default App;
