import classNames from 'classnames';
import Icon from 'components/Icon';

const CampaignLink = ({
  name,
  link,
  start = false,
  styles = ''
}: {
  name: string;
  link: string;
  start?: boolean;
  styles?: string;
}) => {
  return (
    <div
      onClick={() => {
        window.open(`${link}`, '_blank', 'noopener,noreferrer');
      }}
      className={classNames(
        start ? '' : 'ml-6',
        'mt-4 flex items-center text-campaign-link cursor-pointer',
        styles
      )}>
      <span>{name}</span>
      <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
    </div>
  );
};

export default CampaignLink;
