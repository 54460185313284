import { useMemo } from 'react';

import Icon from 'components/Icon';

import classNames from 'classnames';
import DotLoader from 'components/Loaders/DotLoader';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { usePublicBalances } from 'contexts/publicBalancesContext';
import { useSbtToken } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { UpdateVersionNotification } from 'utils/ui/Notifications';
import versionLargerThan from 'utils/versionLarger';
import ButtonWithWallet from '../ButtonWithWallet';

const MintButton = ({
  showMintModal,
  checkBalance = false,
  leftToRight = false
}: {
  showMintModal: () => void;
  checkBalance?: boolean;
  leftToRight?: boolean;
}) => {
  const {
    isMinted,
    gasFee,
    getEthSignature,
    mintLoading,
    mintStatusMsg,
    mintErrMsg,
    proofInfos
  } = useSbtToken();
  const { getNativePublicBalance } = usePublicBalances();
  const nativePublicBalance = getNativePublicBalance();
  const { MIN_REQUIRED_WALLET_VERSION } = useConfig();
  const { mantaWalletVersion } = useMantaWallet();
  const outdated = versionLargerThan(
    MIN_REQUIRED_WALLET_VERSION,
    mantaWalletVersion
  );

  const errorMsg = useMemo(() => {
    if (mintStatusMsg === 'Minted') {
      return '';
    }
    if (mintErrMsg) {
      return mintErrMsg;
    }
    if (
      nativePublicBalance &&
      gasFee?.gt(nativePublicBalance) &&
      checkBalance
    ) {
      return 'Insufficient balance';
    }
    return '';
  }, [mintStatusMsg, mintErrMsg, nativePublicBalance, gasFee, checkBalance]);

  const handleClickMint = async () => {
    if (outdated) {
      UpdateVersionNotification();
      return;
    }
    if (!gasFee) {
      await getEthSignature();
    }
    showMintModal();
  };
  // it won't trigger the loading UI when try to call the getTransactionData function
  // the second time a user changed into another evm address
  const loading = useMemo(() => {
    console.log('mintLoading', mintLoading, proofInfos);
    return mintLoading || !proofInfos?.length;
  }, [mintLoading, proofInfos]);

  const btnComponent = useMemo(() => {
    if (mintStatusMsg) return mintStatusMsg;

    if (loading) {
      return <DotLoader cls="transform scale-150 ml-4" />;
    }

    return 'Mint';
  }, [loading, mintStatusMsg]);

  const disabled = !!errorMsg || !!isMinted || loading;

  return (
    <div className={`flex flex-col ${leftToRight ? '' : 'items-end'}`}>
      <ButtonWithWallet
        btnComponent={btnComponent}
        onClick={disabled ? undefined : handleClickMint}
        disabled={disabled}
        className={`${
          leftToRight ? '' : 'self-end'
        } flex items-center justify-center unselectable-text text-center text-white rounded-lg gradient-button w-60 h-10 filter`}
      />
      {errorMsg && (
        <p
          className={classNames(
            'flex items-center  text-xsss mt-2',
            errorMsg === 'Current address is minting'
              ? 'text-green-500'
              : 'text-error'
          )}>
          <Icon name="information" className="mr-2" /> {errorMsg}
        </p>
      )}
      {!errorMsg && (
        <p className="flex items-center text-white text-opacity-80 text-xsss mt-2">
          <Icon name="information" className="mr-2" /> Gas fee is covered by
          Manta.Network
        </p>
      )}
    </div>
  );
};
export default MintButton;
