import * as allChains from '@wagmi/core/chains';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { publicProvider } from '@wagmi/core/providers/public';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { ReactNode } from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';

import MobileNotSupportedModal from 'components/Modal/mobileNotSupported';
import Navbar from 'components/Navbar';
import { useConfig } from 'contexts/configContext';
import { PublicBalancesContextProvider } from 'contexts/publicBalancesContext';
import { UsdPricesContextProvider } from 'contexts/usdPricesContext';
import { Route, Routes, useNavigate } from 'react-router-dom';
import userIsMobile from 'utils/ui/userIsMobile';
import Main from './Main';
import { SBTContextProvider } from './SBTContext';
import { KusamaChainProvider } from './SBTContext/KusamaChainContext';
import { PolkadotChainProvider } from './SBTContext/PolkadotChainContext';
import { FaceRecognitionContextProvider } from './SBTContext/faceRecognitionContext';
import { GeneratedContextProvider } from './SBTContext/generatedContext';
import { GeneratingContextProvider } from './SBTContext/generatingContext';
import { MintContextProvider } from './SBTContext/mintContext';
import { SBTPrivateContextProvider } from './SBTContext/sbtPrivateWalletContext';
import { SBTThemeContextProvider } from './SBTContext/sbtThemeContext';
import AddressChangeNotification from './components/AddressChangeNotification';
import MintedList from './components/MintedList';
import OnGoingTaskNotification from './components/OnGoingTaskModal';

import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/swiper.scss'; // core Swiper
import { MintedListContextProvider } from './SBTContext/mintedListContext';
import { SbtCommonContextProvider } from './SBTContext/sbtCommonContext';
import Home from './components/Home';
import ProjectDetail from './components/ProjectDetail';
import './index.scss';

const ChainsProviders = ({ children }: { children: ReactNode }) => {
  return (
    <PolkadotChainProvider>
      <KusamaChainProvider>{children}</KusamaChainProvider>
    </PolkadotChainProvider>
  );
};

const SBTProviders = ({ children }: { children: ReactNode }) => {
  return (
    <SBTContextProvider>
      <FaceRecognitionContextProvider>
        <SBTThemeContextProvider>
          <GeneratingContextProvider>
            <GeneratedContextProvider>
              <MintContextProvider>{children}</MintContextProvider>
            </GeneratedContextProvider>
          </GeneratingContextProvider>
        </SBTThemeContextProvider>
      </FaceRecognitionContextProvider>
    </SBTContextProvider>
  );
};

const projectId = 'd31dd9bf162e306e3712cb3b7ca5552f';
const allChainValues = Object.values(allChains);

const { publicClient } = configureChains(allChainValues, [
  w3mProvider({ projectId }),
  publicProvider()
]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains: allChainValues } as any),
  publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, allChainValues);

export const metamaskConnector = new MetaMaskConnector();

const SBT = () => {
  const navigate = useNavigate();

  return (
    <ChainsProviders>
      <SBTProviders>
        <SBTPrivateContextProvider>
          <div className="text-white min-h-screen flex flex-col sbt-page">
            <Navbar />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/list" element={<MintedList />} />
            </Routes>
            <AddressChangeNotification />
            <OnGoingTaskNotification navigate={navigate} />
          </div>
        </SBTPrivateContextProvider>
      </SBTProviders>
    </ChainsProviders>
  );
};
const MintedListWrap = () => (
  <MintedListContextProvider>
    <MintedList />
  </MintedListContextProvider>
);

export const BabSbt = () => {
  const { PAGE_TITLE } = useConfig();
  document.title = PAGE_TITLE;

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <PublicBalancesContextProvider>
          <UsdPricesContextProvider>
            <SbtCommonContextProvider>
              <div className="text-white min-h-screen flex flex-col sbt-page">
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/projects/:projectType"
                    element={<ProjectDetail />}
                  />
                  <Route path="/list" element={<MintedListWrap />} />
                </Routes>
              </div>
            </SbtCommonContextProvider>
          </UsdPricesContextProvider>
        </PublicBalancesContextProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      {userIsMobile() ? <MobileNotSupportedModal /> : null}
    </>
  );
};
export default SBT;
