import Icon from 'components/Icon';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useSbtCommon } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import { SbtTokenContextProvider } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useCallback, useEffect } from 'react';
import homeBanner from 'resources/images/sbt/home-banner.jpg';
import pompBanner from 'resources/images/sbt/pomp-banner.jpg';
import { UpdateVersionNotification } from 'utils/ui/Notifications';
import versionLargerThan from 'utils/versionLarger';
import OnGoingProjects from '../OngoingProjects';
import AboutNPO from './AboutNPO';
import {
  onGoingProjectTypes,
  zkCredentialsProjectTypes,
  zkKYCProjectTypes
} from './ProjectConstant';
import SbtTokenHorizontal from './SbtTokenHorizontal';
import { MINT_TYPES } from './type';

const Home = () => {
  const {
    homeCountData: { today, total, holders }
  } = useSbtCommon();
  const { MIN_REQUIRED_WALLET_VERSION } = useConfig();
  const latestProjectType = onGoingProjectTypes[0];
  const link = 'https://manta.network';
  const handleNavigate = useCallback(() => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }, [link]);

  const { mantaWalletVersion } = useMantaWallet();
  const outdated = versionLargerThan(
    MIN_REQUIRED_WALLET_VERSION,
    mantaWalletVersion
  );

  useEffect(() => {
    if (outdated) UpdateVersionNotification();
  }, [outdated]);

  return (
    <>
      <div
        className="relative h-36 z-10 cursor-pointer"
        onClick={handleNavigate}>
        <img src={homeBanner} className="h-36 w-full" />
        <div className="flex absolute top-0 h-36 w-full">
          <div className="m-auto">
            <div className="font-semibold select-none cursor-pointer text-white text-3xl text-center ">
              Manta’s NFT Private Offering Platform
            </div>
            <div className="flex flex-row justify-center">
              <div className="font-semibold select-none cursor-pointer text-white text-md text-center">
                Learn More About Manta Network
              </div>
              <Icon name="arrowRightCircle" className="ml-2" />
            </div>
          </div>
        </div>
      </div>
      <SbtTokenContextProvider tokenType={latestProjectType as MINT_TYPES}>
        <SbtTokenHorizontal tokenType={latestProjectType as MINT_TYPES} />
      </SbtTokenContextProvider>

      <div className="flex justify-around my-12 text-center">
        <div>
          <p className="mb-2 text-4xl text-gridient font-semibold">{today}</p>
          <p className="text-white text-base font-semibold">
            Newly minted today
          </p>
        </div>
        <div>
          <p className="mb-2 text-4xl text-gridient font-semibold">{total}</p>
          <p className="text-white text-base font-semibold">
            Total zkNFT minted
          </p>
        </div>
        <div>
          <p className="mb-2 text-4xl text-gridient font-semibold">{holders}</p>
          <p className="text-white text-base font-semibold">
            Activated Addresses
          </p>
        </div>
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          window.open('https://atlantic.pomp.money/', '_blank', 'noopener,noreferrer');
        }}>
        <img src={pompBanner} className="w-full" />
      </div>

      <div className="mt-24 flex flex-col items-center mx-auto px-32 pb-8">
        <OnGoingProjects
          title="zkCredentials"
          projectList={zkCredentialsProjectTypes}
          gridClassName="grid-cols-6 gap-6"
        />
        <AboutNPO />
      </div>
    </>
  );
};

export default Home;
