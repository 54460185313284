import Icon from 'components/Icon';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useModal } from 'hooks';
import { useSbtCommon } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import {
  useSbtToken,
  VALID_STATUS
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useMemo } from 'react';
import ButtonWithWallet from '../ButtonWithWallet';
import SbtTokenMintCheckModal from '../SbtTokenMintCheckModal';
import SbtTokenMintedModal from '../SbtTokenMintedModal';
import TokenValidation from '../TokenValidation';
import MintButton from './MintButton';
import { zkTokenMap } from './ProjectConstant';
import { MINT_TYPES, ZK_CATERGORY } from './type';

const BottomTipMsg = () => {
  const { tokenValidStatus: tokenValidStatus } = useSbtToken();
  const { externalAccount } = useMantaWallet();

  if (tokenValidStatus === VALID_STATUS.success && !externalAccount) {
    return (
      <div className="mt-2 leading-4 flex">
        <p className="flex text-white text-opacity-60 text-xss leading-4">
          <Icon name="information" className="mr-2" />
          Connect Manta Wallet to receive free $KMA gas fee coverage.
        </p>
        <a
          href="https://docs.manta.network/docs/guides/MantaWallet"
          target="_blank"
          className="text-check text-xss ml-2"
          rel="noreferrer">
          Click here to learn more about the Manta Wallet
        </a>
      </div>
    );
  }
  return null;
};

const SbtToken = ({ tokenType }: { tokenType: MINT_TYPES }) => {
  const {
    title,
    category,
    content,
    imgUrl,
    detectedMsg,
    noSbtInEvmAddressMsg,
    logo1,
    logo2,
    shouldShowMintedModal = true,
    isExpired,
    customMintButton
  } = zkTokenMap[tokenType];

  const { tokenValidStatus } = useSbtToken();
  const { tokenCountList } = useSbtCommon();
  const { externalAccount } = useMantaWallet();
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeOnBackdropClick: false
  });
  const { ModalWrapper: MintedModalWrapper, showModal: showMintedModal } =
    useModal({
      closeOnBackdropClick: false
    });

  const totalMinted = useMemo(() => {
    let currentCount = 0;
    for (const { token, count } of tokenCountList) {
      if (token === tokenType) {
        currentCount = count;
        break;
      }
    }
    return currentCount;
  }, [tokenType, tokenCountList]);

  const CatergoryBtn = () => {
    switch (category) {
      case ZK_CATERGORY.zkKYC:
        return (
          <div className="flex mb-4">
            <div className="bg-zk-kyc-button border border-zk-kyc leading-4 px-6 py-1 text-white font-red-hat-text text-xs rounded-3xl">
              {category}
            </div>
          </div>
        );
      default:
        return (
          <div className="flex mb-4">
            <div className="bg-zk-credential-button border border-zk-credential leading-4 px-6 py-1 text-white font-red-hat-text text-xs rounded-3xl">
              {category}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex justify-between align-bottom mt-4 bg-white bg-opacity-5 rounded-md p-6">
      <div className="relative">
        <div className="m-auto h-91 w-91 flex flex-col justify-center items-center font-red-hat-mono">
          <img src={imgUrl} className="rounded-lg" />
        </div>
        {shouldShowMintedModal && (
          <div className="text-white text-opacity-80 mt-4 text-sm absolute top-86 left-4 select-none">
            Total Minted: {totalMinted}
          </div>
        )}
      </div>
      <div className="ml-5 relative">
        <div className="flex items-center pb-4 text-2xl">
          {title}{' '}
          {logo1 && logo2 && (
            <div className="ml-5 flex items-center text-xss text-white bg-black px-3 py-0.5">
              <Icon name={logo1} className="mr-3" /> x{' '}
              <Icon name={logo2} className="ml-3" />
            </div>
          )}
        </div>
        <CatergoryBtn />
        {content}
        {customMintButton ? (
          customMintButton()
        ) : (
          <>
            {!isExpired ? (
              <>
                <div className="mt-3.5 text-2xl mb-4 font-semibold">
                  Free Mint
                </div>
                <div>
                  <TokenValidation
                    successMsg={detectedMsg}
                    errorMsg={noSbtInEvmAddressMsg}
                  />
                </div>
              </>
            ) : (
              <button className="mt-6 w-44 px-4 py-2 leading-5  border border-white border-opacity-50 rounded-md text-white text-opacity-50 cursor-not-allowed">
                Expired
              </button>
            )}
            <div className="flex items-start mt-4 justify-between">
              {tokenValidStatus === VALID_STATUS.success && (
                <ButtonWithWallet
                  btnComponent={
                    <div className="flex items-center bg-checked bg-opacity-20 h-10 px-4 rounded-lg">
                      <Icon name="greenCheck" />
                      <p className="ml-4 text-checked text-sm">
                        Manta Wallet Connected
                      </p>
                    </div>
                  }
                  noWalletComponent={
                    <div className="flex items-center gradient-button h-10 px-4 rounded-lg">
                      <Icon
                        name="singleColorManta"
                        className="w-5 h-5 text-white"
                      />
                      <h3 className="ml-4 text-white text-sm">
                        Connect to Manta Wallet
                      </h3>
                    </div>
                  }
                />
              )}
              {tokenValidStatus === VALID_STATUS.success && externalAccount && (
                <div className="flex justify-between">
                  <MintButton showMintModal={showModal} />
                </div>
              )}
            </div>
            <BottomTipMsg />
          </>
        )}
      </div>

      <ModalWrapper>
        <SbtTokenMintCheckModal
          hideModal={hideModal}
          showMintedModal={showMintedModal}
          title={title}
        />
      </ModalWrapper>
      <MintedModalWrapper>
        <SbtTokenMintedModal />
      </MintedModalWrapper>
    </div>
  );
};

export default SbtToken;
