import { u8aToHex } from '@polkadot/util';
export interface TypeData {
  proof: any;
  salt: `0x${string}`;
  chainId: number;
}

export const getSignTypeDataParams = ({ proof, salt, chainId }: TypeData) => {
  const domain = {
    name: 'Claim Free SBT',
    version: '1',
    chainId: chainId,
    salt
  };
  const types = {
    Transaction: [{ name: 'proof', type: 'bytes' }]
  };
  const value = {
    proof
  };
  return {
    domain,
    types,
    value,
    message: {
      proof: u8aToHex(proof)
    }
  };
};
