export enum ZK_CATERGORY {
  zkKYC = 'zkKYC',
  zkCredential = 'zkCredential'
}

export enum MINT_TYPES {
  zkBAB = 'zkBAB',
  zkGalxe = 'zkGalxe',
  zkArbAirdrop = 'zkArbAirdrop',
  zkReadON = 'zkReadON',
  zkLinea = 'zkLinea',
  zkCyberConnect = 'zkCyberConnect',
  zkLineaNFT = 'zkLineaNFT',
  zkUltiverse = 'zkUltiverse',
  zkTaskon = 'zkTaskon',
  zkFrontier = 'zkFrontier',
  zkFuturist = 'zkFuturist',
  zkCertificate = 'zkCertificate',
  zkCertificateNFT = 'zkCertificateNFT',
  zkOP = 'zkOP',
  zkGetaverse = 'zkGetaverse',
  zkKaratDao = 'zkKaratDao',
  zkBurgerCities = 'zkBurgerCities',
  zkBladeDao = 'zkBladeDao'
}

export type HomeCountData = {
  today: number;
  total: number;
  holders: number;
};

export type TokenCountResult = {
  token: MINT_TYPES;
  count: number;
};

export type CountResult = {
  count: number;
  types: Array<TokenCountResult>;
  holders: number;
  todayCount: number;
};
