import AccountSelectMenu from 'components/Accounts/AccountSelectMenu';
import Menu from 'components/Menu/DotMenu';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import ChainSelector from './ChainSelector';
import NavLinks from './NavLinks';
import NavZkNFT from './NavZkNFT';

export const Navbar = () => {
  const { externalAccount } = useMantaWallet();

  return (
    <div>
      <div className="h-20 py-4 px-30 flex justify-between items-center relative sticky left-0 right-0 top-0 z-50 bg-nav">
        <div className="flex items-center">
          <ChainSelector />
          <NavLinks />
        </div>
        <div className="flex">
          {externalAccount && <NavZkNFT />}
          <div className="h-10 gap-4 flex flex-wrap justify-end items-center">
            <AccountSelectMenu />
            <Menu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
