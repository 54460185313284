import { LoadingDots } from 'components/LoadingDots';
import { ChainStateType, useConnectChain } from 'hooks/useConnectChain';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { showInfo } from 'utils/ui/Notifications';
import { useConfig } from './configContext';
export enum API_STATE {
  CONNECT_INIT,
  READY,
  ERROR,
  DISCONNECTED
}
const SubstrateContext = createContext<ChainStateType | null>(null);

const NetworkErrorMsg = () => (
  <div>
    The current network is unstable. Please wait for the network to reconnect
    <LoadingDots />
  </div>
);

const SubstrateContextProvider = ({ children }: { children: ReactNode }) => {
  const config = useConfig();
  const url =
    config.NODES.find((node) => node.name === config.NETWORK_NAME)?.url ||
    config.DEFAULT_SOCKET;
  const value = useConnectChain(url);

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        value.apiState === API_STATE.ERROR ||
        value.apiState === API_STATE.DISCONNECTED ||
        value.apiState === API_STATE.CONNECT_INIT
      ) {
        showInfo(<NetworkErrorMsg />, 2000);
      } else {
        clearInterval(timer);
      }
    }, 4000);
    return () => {
      clearInterval(timer);
    };
  }, [value.apiState]);

  return (
    <SubstrateContext.Provider value={value}>
      {children}
    </SubstrateContext.Provider>
  );
};

const useSubstrate = () => ({ ...useContext(SubstrateContext) });

export { SubstrateContextProvider, useSubstrate };
