import { hexToBn, hexToU8a, u8aToHex } from '@polkadot/util';
import { base58Decode } from '@polkadot/util-crypto';
import axios from 'axios';
import { WalletErrorCode } from 'constants/ErrorCode';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useDebouncedCallback } from 'use-debounce';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { showCustomSuccess, showInfo } from 'utils/ui/Notifications';
import { useAccount } from 'wagmi';
import { GeneratedImg } from '.';
import { zkTokenMap } from '../components/Home/ProjectConstant';
import { MINT_TYPES } from '../components/Home/type';
import { TAG_ORIGINAL_NAME } from '../const';

type GeneratedImgType = GeneratedImg & {
  proof_id?: string;
  asset_id?: string;
  token_type?: MINT_TYPES;
};

export type MintedMap = Record<string, GeneratedImgType[]>;

export enum RestoreStatus {
  Default,
  Loading,
  NoData,
  HasData,
  NoProofs,
  DetectFail,
  Fail,
  Success
}

export type MintListContextValue = {
  tipMessage: string;
  mintedMap: MintedMap;
  loading: boolean;
  getMintedMap: () => Promise<void>;
  saveData: () => Promise<void>;
  postList: Array<any>;
  mintedTypeList: Array<MINT_TYPES>;
  restoreStatus: RestoreStatus;
  createProofTag: (
    proofKey?: string,
    tag?: string,
    assetId?: string,
    token_type?: string
  ) => any;
  getProofKeyTags: (asset_id: string, token_type?: string) => Promise<string[]>;
  getProofKey: (
    tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<Project>;
  deleteProofKey: (
    tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<boolean>;
  updateProofKey: (
    tag: string,
    old_tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<boolean>;
};

export enum PROOF_STATUS_TYPE {
  VACANT = 'Vacant',
  INUSE = 'In Use'
}
export type Project = {
  createAt: number;
  address: string;
  assetId: string;
  project: string;
  proofKey: string;
  status: PROOF_STATUS_TYPE;
};

type MintExtrinsicResult = {
  address: string;
  mintType: MINT_TYPES;
  extrinsicIndex: string;
  post: any;
};

type VirtualAssetResult = {
  valid: boolean;
  message?: string;
  data: {
    asset: any;
    identifier: any;
  };
};

const MintedListContext = createContext<MintListContextValue | null>(null);

const MESSAGE = 'CLAIM TO QUERY NPO NFT LIST';
// eslint-disable-next-line quotes
export const EMPTY_MESSAGE = "You don't currently have any zkSBT";

export const MintedListContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [mintedMap, setMintedMap] = useState<MintedMap>({});
  const [loading, toggleLoading] = useState(true);
  const [tipMessage, setTipMessage] = useState('');
  const [postList, setPostList] = useState<Array<any>>([]);
  const [mintedTypeList, setMintedTypeList] = useState<MINT_TYPES[]>([]);
  const [restoreStatus, setRestoreStatus] = useState<RestoreStatus>(
    RestoreStatus.Default
  );

  const { SBT_NODE_SERVICE, NETWORK_NAME } = useConfig();
  const { externalAccount, signRaw, privateWallet } = useMantaWallet();
  const { address } = useAccount();

  const signRawSession = useCallback(
    async (message: string) => {
      const sessionSignature = sessionStorage.getItem(MESSAGE || message);
      const signature = sessionSignature || (await signRaw(message));
      if (signature) sessionStorage.setItem(message, signature);
      return signature;
    },
    [signRaw]
  );

  const getMintedMap = useCallback(async () => {
    toggleLoading(true);
    if (!externalAccount?.address) {
      setTipMessage('Please connect wallet first');
      toggleLoading(false);
      return;
    }
    setTipMessage('');
    try {
      const signature = await signRawSession(MESSAGE);
      if (!signature) {
        return;
      }
      const url = `${SBT_NODE_SERVICE}/npo/nftlist`;
      const data = {
        address: externalAccount?.address,
        message: MESSAGE,
        signature
      };
      const ret = await axios.post<MintedMap>(url, data);
      if (ret.status === 200 || ret.status === 201) {
        setMintedMap(ret.data);
        if (!Object.keys(ret.data).length) {
          setTipMessage(EMPTY_MESSAGE);
        } else {
          setTipMessage('');
        }
      } else {
        setTipMessage('Unknown Error, please refresh the page');
      }
      toggleLoading(false);
    } catch (e: any) {
      console.log('query nft list error: ', e);
      if (e.code === WalletErrorCode.rejected) {
        setTipMessage(
          'Please approve to the signature to view your zkSBT list'
        );
      } else {
        setTipMessage(e.code);
      }
      toggleLoading(false);
    }
  }, [SBT_NODE_SERVICE, externalAccount?.address, signRawSession]);

  const getEncodePostList = useCallback((postObjList: Array<any>) => {
    return postObjList?.map((postObj: any) => {
      const sources = postObj?.sources?.map((item: any) =>
        Array.from(hexToU8a(item, 16 * 8))
      );
      const receiverPosts = postObj?.receiverPosts?.map((receiverPost: any) => {
        const utxo = receiverPost.utxo;
        const fullIncomingNote = receiverPost.fullIncomingNote;

        const incomingNote = {} as any;
        incomingNote.ephemeral_public_key = Array.from(
          hexToU8a(fullIncomingNote?.incomingNote?.ephemeralPublicKey, 32 * 8)
        );
        incomingNote.tag = Array.from(
          hexToU8a(fullIncomingNote?.incomingNote?.tag, 32 * 8)
        );
        incomingNote.ciphertext =
          fullIncomingNote?.incomingNote?.ciphertext.map((item: any) =>
            Array.from(hexToU8a(item, 32 * 8))
          );

        const lightIncomingNote = {} as any;
        lightIncomingNote.ephemeral_public_key = Array.from(
          hexToU8a(
            fullIncomingNote?.lightIncomingNote?.ephemeralPublicKey,
            32 * 8
          )
        );
        lightIncomingNote.ciphertext =
          fullIncomingNote?.lightIncomingNote?.ciphertext.map((item: any) => {
            return Array.from(hexToU8a(item, 32 * 8));
          });
        const encodeReceiverPost = {
          utxo: {
            is_transparent: utxo?.isTransparent,
            public_asset: {
              id: Array.from(hexToU8a(utxo?.publicAsset?.id, 32 * 8)),
              value: Array.from(hexToU8a(utxo?.publicAsset?.value, 16 * 8))
            },
            commitment: Array.from(hexToU8a(utxo?.commitment, 32 * 8))
          },
          full_incoming_note: {
            address_partition: fullIncomingNote.addressPartition || [],
            incoming_note: incomingNote,
            light_incoming_note: lightIncomingNote
          }
        };
        return encodeReceiverPost;
      });

      return {
        sink_accounts: postObj?.sinkAccounts ?? [],
        sinks: postObj?.sinks ?? [],
        sender_posts: postObj?.senderPosts ?? [],
        sources,
        asset_id: Array.from(hexToU8a(postObj?.assetId, 32 * 8)),
        authorization_signature: postObj?.authorizationSignature || [],
        receiver_posts: receiverPosts,
        proof: Array.from(hexToU8a(postObj?.proof, 128 * 8))
      };
    });
  }, []);

  const checkRestoreData = useCallback(async () => {
    if (loading || !address || !externalAccount?.address) {
      return;
    }
    setRestoreStatus(RestoreStatus.Loading);
    const url = `${SBT_NODE_SERVICE}/npo/mintExtrinsic`;
    const data = {
      address: address.toLowerCase()
    };
    try {
      const ret = await axios.post<{ data: MintExtrinsicResult[] }>(url, data);

      if (ret.status === 200 || ret.status === 201) {
        const extrinsicList = ret?.data?.data;
        if (!extrinsicList?.length) {
          setRestoreStatus(RestoreStatus.NoData);
          return;
        }

        const postList: any[] = [];
        const mintedTypeList: MINT_TYPES[] = [];
        const mintedList = Object.values(mintedMap).flat();
        for (let i = 0; i < extrinsicList.length; i++) {
          const { mintType, post } = extrinsicList[i];
          const alreadyHasMintedData = mintedList.some(
            (mintedData) => mintedData.token_type === mintType
          );
          if (!alreadyHasMintedData) {
            mintedTypeList.push(mintType as MINT_TYPES);
            postList.push(post);
          }
        }
        setPostList(postList);
        setMintedTypeList(mintedTypeList);
        if (postList.length && mintedTypeList.length) {
          setRestoreStatus(RestoreStatus.HasData);
        } else {
          setRestoreStatus(RestoreStatus.NoData);
        }
      }
    } catch (e) {
      console.error('check restore data error: ', e);
      setRestoreStatus(RestoreStatus.DetectFail);
    }
  }, [loading, address, externalAccount?.address, SBT_NODE_SERVICE, mintedMap]);

  useEffect(() => {
    checkRestoreData();
  }, [checkRestoreData]);

  const saveData = useCallback(async () => {
    const encodePostList = getEncodePostList(postList);
    const arrayedPostList = encodePostList.map((post) => [post]);

    if (privateWallet?.getSbtTransactionDatas) {
      setRestoreStatus(RestoreStatus.Loading);
      try {
        const transactionDatas = await privateWallet.getSbtTransactionDatas({
          posts: arrayedPostList,
          network: NETWORK_NAME
        });

        const proofsUrl = `${SBT_NODE_SERVICE}/npo/proofs`;

        const bytes = base58Decode(
          (externalAccount?.meta?.zkAddress as string) ?? ''
        );
        const addressBytes = Array.from(bytes);
        const proofInfos: Array<any> = [];
        transactionDatas?.forEach((tx: any, index) => {
          if (!tx?.[0]) {
            return;
          }
          const proofId = u8aToHex(
            tx[0].ToPrivate[0]['utxo_commitment_randomness']
          );
          const identifier = tx[0].ToPrivate[0];
          const asset_info = tx[0].ToPrivate[1];
          proofInfos.push({
            transaction_data: {
              asset_info: {
                id: asset_info.id,
                value: Number(asset_info.value)
              },
              identifier,
              zk_address: {
                receiving_key: addressBytes
              }
            },
            proof_id: proofId,
            asset_id: hexToBn(postList[index].assetId, {
              isLe: true
            }).toString(),
            blur_url:
              zkTokenMap[mintedTypeList[index] as keyof typeof MINT_TYPES]
                .imgUrl,
            eth_address: address,
            token_type: mintedTypeList[index]
          });
        });
        if (!proofInfos.length) {
          setRestoreStatus(RestoreStatus.NoProofs);
          return;
        }
        const proofsData = {
          proof_info: proofInfos,
          address: externalAccount?.address,
          model_id: '',
          token_type: mintedTypeList[0]
        };

        await axios.post<{ status: boolean }>(proofsUrl, proofsData);
        setRestoreStatus(RestoreStatus.Success);
        getMintedMap();
      } catch (e) {
        console.error('save data error: ', e);
        setRestoreStatus(RestoreStatus.Fail);
      }
    } else {
      console.error('Manta Wallet old version');
      showInfo('Manta Wallet version is low');
    }
  }, [
    NETWORK_NAME,
    SBT_NODE_SERVICE,
    address,
    externalAccount?.address,
    externalAccount?.meta?.zkAddress,
    getEncodePostList,
    mintedTypeList,
    postList,
    privateWallet,
    getMintedMap
  ]);

  const getProofKeyTags = useCallback(
    async (asset_id, token_type) => {
      const address = externalAccount?.address;
      const message = 'GET PROOF KEY TAGS';

      const proofKeysURL = `${SBT_NODE_SERVICE}/npo/proofkey/getProofKeyTags `;
      try {
        const signature = await signRaw(message);
        if (!signature) {
          return [''];
        }
        const res = await axios.post<{ count: number; data: string[] }>(
          proofKeysURL,
          {
            address,
            message,
            signature,
            asset_id,
            token_type
          }
        );
        return res?.data?.data || [];
      } catch (error) {
        console.log('/npo/getProofKeyTags ', error);
        return [''];
      }
    },
    [SBT_NODE_SERVICE, externalAccount?.address, signRaw]
  );

  const getProofKey = useCallback(
    async (asset_id, tag, token_type) => {
      const address = externalAccount?.address;
      const message = 'GET PROOF KEY DETAIL';
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/proofkey/getProofKey`;
      try {
        const signature = await signRaw(message);
        if (!signature) {
          return {} as Project;
        }
        const res = await axios.post<Project>(proofKeyURL, {
          address,
          message,
          signature,
          asset_id,
          project: tag,
          token_type
        });
        return res?.data || {};
      } catch (error) {
        console.log('/npo/proofkey/getProofKey', error);
        return {} as Project;
      }
    },
    [SBT_NODE_SERVICE, externalAccount?.address, signRaw]
  );

  const deleteProofKey = useCallback(
    async (tag, asset_id, token_type) => {
      const address = externalAccount?.address;
      const message = 'DELETE PROOF KEY';
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/proofkey/deleteProofKey`;
      try {
        const signature = await signRaw(message);
        if (!signature) {
          return false;
        }
        const res = await axios.post<{ valid: boolean }>(proofKeyURL, {
          asset_id,
          project: tag,
          address,
          message,
          signature,
          token_type
        });
        const { valid } = res?.data || { valid: false };
        if (valid)
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>successfully deleted</div>
            </div>,
            2000
          );
        return valid;
      } catch (error) {
        console.log('/npo/deleteProofKey', error);
        return false;
      }
    },
    [SBT_NODE_SERVICE, externalAccount?.address, signRaw]
  );

  const updateProofKey = useCallback(
    async (tag, old_tag, asset_id, token_type) => {
      const address = externalAccount?.address;
      const message = 'UPDATE PROOF KEY';
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/proofkey/updateProofKeyTag`;
      try {
        const signature = await signRaw(message);
        if (!signature) {
          return false;
        }
        const res = await axios.post<{ status: boolean }>(proofKeyURL, {
          asset_id,
          project: tag,
          project_old: old_tag,
          address,
          message,
          signature,
          token_type
        });
        const success = res?.data?.status || false;
        if (success) {
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>successfully updated</div>
            </div>,
            2000
          );
        }
        return success;
      } catch (error) {
        console.log('/npo/updateProofKey', error);
        return false;
      }
    },
    [SBT_NODE_SERVICE, externalAccount?.address, signRaw]
  );

  const createProofTag = useDebouncedCallback(
    useCallback(
      async (proofKey, tag, asset_id, token_type) => {
        const virtualAssetURL = `${SBT_NODE_SERVICE}/npo/proofkey/virtualAsset`;
        const verifyPostURL = `${SBT_NODE_SERVICE}/npo/proofkey/verifyPost`;
        const address = externalAccount?.address;
        const zkAddress = externalAccount?.meta?.zkAddress;

        const data = {
          asset_id,
          project: tag,
          zkAddress,
          address,
          proofKey,
          token_type
        };
        try {
          const message = 'CREATE VIRTUAL ASSET';
          const signature = await signRaw(message);
          if (!signature) return;
          const asset = await axios.post<VirtualAssetResult>(virtualAssetURL, {
            ...data,
            signature,
            message
          });
          const {
            valid: _valid,
            data: _data,
            message: _message
          } = asset.data || {};

          if (tag?.toLowerCase() === TAG_ORIGINAL_NAME && _valid) {
            // just for original case
            showCustomSuccess(
              <div className="flex flex-col -mt-3">
                <div>Original Proof Key</div>
                <div>successfully restored</div>
              </div>,
              2000
            );
            return asset.data;
          }

          if (!_valid) {
            showInfo(_message);
            return {
              valid: _valid,
              project: tag,
              message: _message || ''
            };
          }
          const virtualAsset = JSON.stringify(_data);
          const proof = await privateWallet.getSbtIdentityProof({
            virtualAsset,
            polkadotAddress: externalAccount?.address || '',
            network: NETWORK_NAME
          });
          const vMessage = 'CREATE PROOF TAG';
          const vSignature = await signRaw(vMessage);
          if (!vSignature) return;
          const verifyPost = await axios.post<{
            valid: boolean;
            proof_key?: string;
            message?: string;
          }>(verifyPostURL, {
            post: proof,
            ...data,
            signature: vSignature,
            message: vMessage
          });
          const { valid, message: verifyMessage } = verifyPost?.data || {
            proof_key: '',
            valid: false
          };
          if (valid) {
            showCustomSuccess(
              <div className="flex flex-col -mt-3">
                <div>New Proof Key</div>
                <div>successfully created</div>
              </div>,
              2000
            );
          } else {
            showInfo(verifyMessage);
          }
          return verifyPost?.data;
        } catch (e) {
          console.log('createProofTag', e);
          return;
        }
      },
      [NETWORK_NAME, SBT_NODE_SERVICE, externalAccount?.address, externalAccount?.meta?.zkAddress, privateWallet, signRaw]
    ),
    1000
  );

  const value = useMemo(
    () => ({
      mintedMap,
      loading,
      tipMessage,
      getMintedMap,
      postList,
      mintedTypeList,
      saveData,
      restoreStatus,
      createProofTag,
      getProofKeyTags,
      getProofKey,
      updateProofKey,
      deleteProofKey
    }),
    [
      mintedMap,
      loading,
      tipMessage,
      getMintedMap,
      postList,
      mintedTypeList,
      saveData,
      restoreStatus,
      createProofTag,
      getProofKeyTags,
      getProofKey,
      updateProofKey,
      deleteProofKey
    ]
  );

  return (
    <MintedListContext.Provider value={value}>
      {children}
    </MintedListContext.Provider>
  );
};

export const useMintedList = () => {
  const data = useContext(MintedListContext);
  if (!data || !Object.keys(data).length) {
    throw new Error(
      'useMintedList can only be used inside of <MintedListContext />, please declare it at a higher level.'
    );
  }
  return data;
};
