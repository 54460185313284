import classNames from 'classnames';
import { useKeyring } from 'contexts/keyringContext';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import ConnectWallet from './ConnectWallet';
import AccountSelectDropdown from './AccountSelectDropdown';

const DisplayAccountsButton = () => {
  const { selectedWallet } = useKeyring();
  const { externalAccount } = useMantaWallet();
  const [showAccountList, setShowAccountList] = useState(false);

  const succinctAccountName =
    (externalAccount?.meta?.name as string)?.length > 11
      ? `${(externalAccount?.meta?.name as string)?.slice(0, 11)}...`
      : (externalAccount?.meta?.name as string);

  const ExternalAccountBlock = ({ text }: { text: string }) => {
    return (
      <>
        <img
          className="unselectable-text w-6 h-6 rounded-full"
          src={selectedWallet?.logo?.src}
          alt={selectedWallet?.logo?.alt}
        />
        <p className="unselectable-text">{text}</p>
      </>
    );
  };

  return (
    <div className="relative">
      <OutsideClickHandler onOutsideClick={() => setShowAccountList(false)}>
        <div
          className={classNames(
            'flex flex-row justify-center h-10 gap-3 border border-white-light bg-fifth dark:text-black dark:text-white font-red-hat-text w-44 text-sm cursor-pointer rounded-lg items-center'
          )}
          onClick={() => setShowAccountList(!showAccountList)}>
          <ExternalAccountBlock text={succinctAccountName} />
        </div>
        {showAccountList && (
          <div className="w-70 flex flex-col mt-3 absolute right-0 top-full border border-white-light rounded-lg text-black dark:text-white">
            <div className="max-h-96 overflow-y-auto bg-primary px-5 py-5 rounded-b-lg">
              <AccountSelectDropdown />
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

const AccountSelectMenu = () => {
  const { externalAccount } = useMantaWallet();

  return externalAccount ? (
    <DisplayAccountsButton />
  ) : (
    <ConnectWallet
      isButtonShape={true}
      className={
        'bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg'
      }
    />
  );
};

export default AccountSelectMenu;
